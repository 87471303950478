import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Grid } from 'semantic-ui-react'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import UserCard from 'Pages/Subjects/UserCard'
import useBreadcrumbs from '../useBreadcrumbs'
import ObsModuleStats from './ObsModuleStats'
import ObsModuleOutcomes from './ObsModuleOutcomes'
// import ObsModuleIntroVideo from './ObsModuleIntroVideo'
import ObsModuleCertificate from './ObsModuleCertificate'
import ObsModuleLearnerActions from './ObsModuleLearnerActions'
import ObsModuleDescription from './ObsModuleDescription'
// import ObsModuleInstructions from './ObsModuleInstructions'

const ObsModuleContent = ({ setBreadcrumbs, data, module, lessons, subject }) => {
	const { userId, userSubjectId, obsCourseId, obsModuleId } = useParams()

	const [user, setUser] = useState(null)

	const {
		name,
		progress,
		assessment,
		firstAssessment,
		obs_learning_stats: obsLearningStats,
		subject: { name: subjectName, auto_sign_off_enabled },
	} = data.byId

	const { default_force_learning: defaultForceLearning } = subject.byId

	useBreadcrumbs({
		userId,
		userSubjectId,
		obsCourseId,
		subjectName,
		name,
		setBreadcrumbs,
		user,
	})

	const getResponsiveWidth = () => {
		const width = 16

		if (userId) {
			return { width }
		}

		return {
			mobile: width,
			tablet: width,
			computer: width,
		}
	}

	return (
		<div className="section-info">
			<Grid reversed="mobile">
				<Grid.Column {...getResponsiveWidth()}>
					<div className={classNames('page-title', { flex: userId })}>
						{userId && <UserCard setUser={setUser} />}
						<h1>{module.name}</h1>
						<ObsModuleDescription content={module.description} />
						{/* <ObsModuleInstructions /> */}
						<ObsModuleStats
							progress={progress}
							firstAssessment={firstAssessment}
							auto_sign_off_enabled={auto_sign_off_enabled}
							assessment={assessment}
							isManager={Boolean(userId)}
							obsLearningStats={obsLearningStats}
							lessons={lessons}
						/>
					</div>
				</Grid.Column>
			</Grid>
			<ObsModuleCertificate {...subject.byId} />
			{/* <ObsModuleIntroVideo content={module.introductionVideo} /> */}
			<ObsModuleOutcomes
				userId={userId}
				moduleId={obsModuleId}
				learningOutcomes={module.learningOutcomes}
				lessons={lessons}
				obsLearningStats={obsLearningStats}
			/>
			<ObsModuleLearnerActions
				obsLearningStats={obsLearningStats}
				lessons={lessons}
				defaultForceLearning={defaultForceLearning}
				auto_sign_off_enabled={auto_sign_off_enabled}
			/>
		</div>
	)
}

ObsModuleContent.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	module: PropTypes.object,
	lessons: PropTypes.array,
	subject: PropTypes.object,
}

ObsModuleContent.defaultProps = {
	module: {},
	lessons: [],
	subject: {},
}

export default withBreadcrumbs(ObsModuleContent)
