import React, { memo } from 'react'
import PropTypes from 'prop-types'
import OutcomeContent from './OutcomeContent'
import LearningStatus from './LearningStatus'
import LearningStats from './LearningStats'

const ObsModuleOutcome = ({ index, content, ...restOutcomeProps }) => (
	<li className="outcome-item obs-outcome-item">
		<div className="ref">
			<span>{index}.</span>
		</div>
		<OutcomeContent content={content} />
		<LearningStatus {...restOutcomeProps} />
		<LearningStats {...restOutcomeProps} />
	</li>
)

ObsModuleOutcome.propTypes = {
	index: PropTypes.number.isRequired,
	content: PropTypes.string.isRequired,
}

export default memo(ObsModuleOutcome)
