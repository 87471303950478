import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withContext } from 'Context'
import { ProgressBar } from 'Common'
import { allRoles, isReviewerRole } from 'Utils/roles'
import EditSubjectData from 'Modals/subjects/EditSubjectData'
import useScormSubjectStats from './useScormSubjectStats'

const ScormSubjectLearnerStats = ({ userRole, userRoleType, ...restSubjectProps }) => {
	const { scorm_course_id, isLearnerView } = restSubjectProps

	const [editSubjectModal, setEditSubjectModal] = useState({ open: false })

	const isReviewer = isLearnerView || isReviewerRole(userRole, userRoleType)

	const { progress, scormRegistrationProgress } = useScormSubjectStats(restSubjectProps)

	return (
		<div className="subject-progress">
			{!isLearnerView && <h4>Section ID: {scorm_course_id}</h4>}
			<ProgressBar title="Section progress" percents={progress} />
			{Boolean(scormRegistrationProgress) && (
				<>
					<div className="mt_25">
						<h5>Section Details:</h5>
						<p>Title: {scormRegistrationProgress.course.title}</p>
						<p>Version: {scormRegistrationProgress.course.version}</p>
					</div>
					<div className="mt_25">
						<h5>Activity Details:</h5>
						<p>
							{`Total time spent: ${' '}
							${moment.utc(scormRegistrationProgress.totalSecondsTracked * 1000).format('HH:mm:ss')}`}
						</p>
						<p>Completion: {scormRegistrationProgress.activityDetails.activityCompletion}</p>
						<p>Success: {scormRegistrationProgress.activityDetails.activitySuccess}</p>
						<p>Attempts: {scormRegistrationProgress.activityDetails.attempts}</p>
						{/* <p>
							{`Progress Score (Scaled Completion Amount):${' '}
							${scormRegistrationProgress.activityDetails.completionAmount.scaled || 'No score'}`}
						</p> */}
						{Boolean(scormRegistrationProgress.firstAccessDate) && (
							<p>
								{`First access date:${' '}
								${moment(scormRegistrationProgress.firstAccessDate).format('DD/MM/YYYY')}`}
							</p>
						)}
						{Boolean(scormRegistrationProgress.lastAccessDate) && (
							<p>
								{`Last access date:${' '}
								${moment(scormRegistrationProgress.lastAccessDate).format('DD/MM/YYYY')}`}
							</p>
						)}
					</div>
				</>
			)}
			{!isReviewer && editSubjectModal.open && (
				<EditSubjectData {...editSubjectModal} onClose={() => setEditSubjectModal({ open: false })} />
			)}
		</div>
	)
}

ScormSubjectLearnerStats.propTypes = {
	userRole: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	userRoleType: PropTypes.string.isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		userRole: role,
		userRoleType: roleType,
	}),
	ScormSubjectLearnerStats,
)

function TargetCompletionProgress({
	isCompleted,
	isReviewer,
	isTargetCompletionSet,
	dateCompleted,
	targetCompletion,
	remainingInPercents,
	onChange,
}) {
	const renderUncompletedTitle = () => {
		const getTargetEndDate = (fallbackLabel = '') => {
			if (targetCompletion.isValid()) {
				return targetCompletion.format('DD/MM/YYYY')
			}

			return fallbackLabel
		}

		return (
			<>
				<span>{`Target end date: ${getTargetEndDate('not set')}`}</span>
				{!isReviewer && (
					<button
						className="edit_end_date"
						type="button"
						onClick={() =>
							onChange({
								open: true,
								field: 'target_completion',
								initialValue: getTargetEndDate(),
							})
						}
					>
						<i className="fas fa-pen" />
					</button>
				)}
			</>
		)
	}

	const renderTitle = () => {
		if (isCompleted) {
			return `Signed Off on ${dateCompleted}`
		}

		return renderUncompletedTitle()
	}

	const getColor = () => (isCompleted ? 'primary' : 'secondary')

	const getPercents = () => {
		if (isTargetCompletionSet) {
			return remainingInPercents < 100 ? remainingInPercents : 100
		}

		return 0
	}

	return <ProgressBar inDays title={renderTitle()} percents={getPercents()} color={getColor()} />
}

TargetCompletionProgress.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	isTargetCompletionSet: PropTypes.bool.isRequired,
	dateCompleted: PropTypes.string.isRequired,
	targetCompletion: PropTypes.object.isRequired,
	remainingInPercents: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
}
