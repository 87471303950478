import React from 'react'
import { Container } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const Centered = ({ children }) => (
	<Container
		text
		style={{
			display: 'grid',
			placeContent: 'center',
			height: '100vh',
		}}
		textAlign="center"
	>
		{children}
	</Container>
)

Centered.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Centered
