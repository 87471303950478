import React, { memo } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Button } from 'semantic-ui-react'
import IrreversibleConfirmation from 'Modals/common/IrreversibleConfirmation'

const UpdateConfirmationDialog = ({ className, total, loading, disabled, opened, onCancel, onUpdate }) => {
	const renderContent = () => {
		return `You are about to update ${pluralize(
			'course',
			total,
			true,
		)}. There is no way to restore these dates once completed. Are you sure you want to continue?`
	}

	const renderConfirmationHelperContent = () => {
		return `To confirm the update please enter the number of ${pluralize('course', total)} you are updating into
		the box below.`
	}

	const renderCancelButton = () => {
		return <Button className="reverse" size="tiny" disabled={disabled} content="Cancel" onClick={onCancel} />
	}

	const renderConfirmButton = confirmInputValue => {
		const isDisabled = Number(confirmInputValue) !== Number(total)

		return (
			<Button
				negative
				loading={loading}
				disabled={isDisabled || disabled}
				size="tiny"
				content="Update"
				onClick={onUpdate}
			/>
		)
	}

	return (
		<IrreversibleConfirmation
			className={className}
			opened={opened}
			content={renderContent()}
			confirmationHelperContent={renderConfirmationHelperContent()}
			renderCancelButton={renderCancelButton}
			renderConfirmButton={renderConfirmButton}
		/>
	)
}

UpdateConfirmationDialog.propTypes = {
	total: PropTypes.number.isRequired,
	onCancel: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	className: PropTypes.string,
	loading: PropTypes.bool,
	opened: PropTypes.bool,
	disabled: PropTypes.bool,
}

UpdateConfirmationDialog.defaultProps = {
	className: '',
	opened: false,
	disabled: false,
	loading: false,
}

export default memo(UpdateConfirmationDialog)
