import React from 'react'
import PropTypes from 'prop-types'
import RebrandingHeader from './RebrandingHeader'

import './style.scss'

const RebrandingMigrate = ({ children, Layout }) => (
	<Layout>
		<RebrandingHeader />
		<div className="migrate-conent">{children}</div>
	</Layout>
)

RebrandingMigrate.defaultProps = {
	Layout: 'div',
}

RebrandingMigrate.propTypes = {
	children: PropTypes.node.isRequired,
	Layout: PropTypes.elementType,
}

export default RebrandingMigrate
