import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Cookie from 'js-cookie'
import { Modal, Checkbox, List, Image, Button } from 'semantic-ui-react'
import { withContext } from 'Context'
import useAccounts from 'Hooks/useAccounts'
import { profileUpdateAction } from 'Actions/profileActions'
import { appLoadingSwitch as appLoadingSwitchAction } from 'Actions/appActions'
import styles from './AccountSelector.module.scss'
import './AccountSelector.scss'

const AccountSelector = ({ user, updateProfile, appLoadingSwitch }) => {
	const [open, setOpen] = useState(false)
	const [selectedAccountId, setSelectedAccountId] = useState(null)

	const { loading, changeAccountLoading, getAccounts, getCurrentAccount, changeAccount } = useAccounts({
		user,
		updateProfile,
		appLoadingSwitch,
	})

	const helpers = {
		selectedAccountId,
		getAccounts,
		getCurrentAccount,
	}
	const helpersRef = useRef(helpers)
	helpersRef.current = helpers

	const onModalClose = () => {
		Cookie.set('accountId', selectedAccountId)
		setOpen(false)
	}

	const onApplyChanges = () => changeAccount({ accountId: selectedAccountId, onSuccess: onModalClose })

	const onSelectedAccountChange = accountId => setSelectedAccountId(accountId)

	// open modal if accountId not available in session storage
	useEffect(() => {
		const isAccountSelectorViewed = Boolean(Cookie.get('accountId'))
		const { getAccounts } = helpersRef.current

		if (!loading && !isAccountSelectorViewed && getAccounts().length > 1) {
			setOpen(true)
		}
	}, [loading])

	// preselect account id
	useEffect(() => {
		const { getCurrentAccount, selectedAccountId } = helpersRef.current

		if (!loading && user && !selectedAccountId) {
			const currentAccount = getCurrentAccount()
			const currentAccountId = (currentAccount && currentAccount.id) || selectedAccountId

			setSelectedAccountId(currentAccountId)
			helpersRef.current.selectedAccountId = currentAccountId
		}
	}, [loading, user])

	// reset state if session lost
	useEffect(() => {
		if (!user) {
			Cookie.remove('accountId')
			Cookie.remove('dashboard')
		}
	}, [user])

	return (
		<Modal open={open} size="small" closeOnDimmerClick={false} onClose={onModalClose}>
			<Modal.Header>Choose account</Modal.Header>
			<Modal.Content>
				<List divided verticalAlign="middle" size="massive">
					{getAccounts().map(({ id, name, photo }) => (
						<List.Item key={id} className={classNames('account-selector__item', styles.listItem)}>
							<Checkbox
								toggle
								className={styles.checkbox}
								checked={id === selectedAccountId}
								onChange={() => onSelectedAccountChange(id)}
							/>
							<Image avatar src={photo} size="small" />
							<List.Content>
								<List.Header className={styles.listHeader}>{name}</List.Header>
							</List.Content>
						</List.Item>
					))}
				</List>
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Select"
					labelPosition="right"
					icon="checkmark"
					disabled={!selectedAccountId}
					className={styles.actionPositive}
					loading={changeAccountLoading}
					onClick={onApplyChanges}
					positive
				/>
			</Modal.Actions>
		</Modal>
	)
}

AccountSelector.propTypes = {
	user: PropTypes.object.isRequired,
	updateProfile: PropTypes.func.isRequired,
	appLoadingSwitch: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			user: { user },
		},
		dispatch,
	]) => ({
		// State
		user,
		// Actions
		updateProfile: data => profileUpdateAction(data, dispatch),
		appLoadingSwitch: () => appLoadingSwitchAction(dispatch),
	}),
	AccountSelector,
)
