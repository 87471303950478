import React, { useState } from 'react'
import PropsTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import ObsLessonMaterials from './ObsLessonMaterials'
import ReportBrokenLink from './ReportBrokenLink'

const ObsLessonContent = ({ lesson, subjectSectionData }) => {
	const [activePage, setActivePage] = useState(0)

	const { userSubjectId, subjectSectionId, obsCourseId, obsModuleId } = useParams()

	const getBackToCourseUrl = () => {
		return [
			Routes.SUBJECTS.path,
			userSubjectId,
			subjectSectionId,
			OBS_SUBJECT_PREFIX,
			obsCourseId,
			obsModuleId,
		].join('/')
	}

	const { title = '', introduction = '', content = [] } = lesson
	const lessonContent = [
		{
			content: introduction,
		},
		...content,
	]

	return (
		<>
			<div className="section-learn">
				<div className="page-title">
					<Link className="button-link primary" to={getBackToCourseUrl()}>
						Back to course
					</Link>
				</div>
				<div className="section-learn" style={{ height: 'auto', marginTop: 50 }}>
					<div className="page-title">
						<h1>{title}</h1>
					</div>
					<div className="mt_25 page-content">
						<ObsLessonMaterials
							content={lessonContent}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</div>
				</div>
			</div>
			<ReportBrokenLink
				learningOutcomeName={title}
				page={activePage}
				subjectName={subjectSectionData.subject.name}
				sectionName={subjectSectionData.name}
			/>
		</>
	)
}

ObsLessonContent.propTypes = {
	lesson: PropsTypes.object,
	subjectSectionData: PropsTypes.object,
}

ObsLessonContent.defaultProps = {
	lesson: {},
	subjectSectionData: {},
}

export default ObsLessonContent
