import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Routes } from 'Constants/Routes'
import PropTypes from 'prop-types'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { Button, Loader } from 'semantic-ui-react'

import { GET_SUBJECTS_TOPIC, GET_USERS_MATRIX_DATA_TOPIC } from 'Queries/matrixQueries'
import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import { EXPORT_TRAINING_MATRIX } from 'Mutations/exportMutations'
import { StateContext } from 'Context/index'
import { isLearnerRole } from 'Utils/roles'
import useExport from 'Hooks/useExport'

import Grid from './Grid'
import Filter from './Filter'
import IntroCtaMessage from './IntroCtaMessage'

import './style.scss'

const initialFilters = {
	page: 1,
	due_in_days: 30,
	sort_direction: 'asc',
	sort_param: 'first_name',
}

const Matrix = ({ toastManager }) => {
	const [
		{
			user: {
				user: {
					id,
					account: { name: accountName, role: accountRole, roleType: accountRoleType },
				},
			},
		},
	] = useContext(StateContext)

	const columns = [
		{
			name: 'First Name',
			column: 'first_name',
		},
		{
			name: 'Last Name',
			column: 'last_name',
		},
		{
			name: 'Group',
			column: 'group',
		},
		{
			name: 'Employment Start Date',
			column: 'date_start',
		},
		{
			name: 'Learner compliance',
			column: 'learner_compliance',
		},
	]

	const [isASC, setIsAsc] = useState(true)
	const [isSubjectsFlag, setSubjectsFlag] = useState(true)

	const [updateProfileMutation] = useMutation(UPDATE_PROFILE_MUTATION)
	const [getMatrixData, { data: loadedData, fetchMore, refetch, loading, updateQuery }] = useLazyQuery(
		GET_USERS_MATRIX_DATA_TOPIC,
		{
			fetchPolicy: 'cache-and-network',
		},
	)

	const [getSubjects, { data: subjects, refetch: refetchSubjects, loading: subjectLoading }] = useLazyQuery(
		GET_SUBJECTS_TOPIC,
	)

	const tableColumns = useMemo(() => {
		if (subjects) {
			const sortSubjectsList = subjects.topicTrainingMatrixStats.sort()
			// const sortSubjectsList = subjects.topicTrainingMatrixStats.sort((a, b) => a.name.localeCompare(b.name))
			return columns.concat(sortSubjectsList)
		} else return []
	}, [subjects])

	const isLearner = useMemo(() => {
		return isLearnerRole(accountRole, accountRoleType)
	}, [accountRole, accountRoleType])

	const [filters, setFilters] = useState(
		!isLearner ? { ...initialFilters, active: true } : { learners: [id], ...initialFilters },
	)

	const usersData = useMemo(() => {
		if (loadedData && tableColumns.length) {
			const subjectsInfo = {}

			const courseCompletedCountRow = {
				name: 'Overdue Learners',
				tooltip: `Shows the number of Learners who are overdue for this topic.`,
			}
			const courseComplianceCountRow = {
				name: `Topic Compliance Percentage`,
				tooltip: 'Shows the percentage of learners who are compliant.',
			}
			tableColumns.map(column => {
				if (column.id) {
					const percentage = Math.round((column.topic_compliance_count / column.topic_allocated_count) * 100)
					courseCompletedCountRow[column.id] = column.topic_overdue_count
					courseComplianceCountRow[column.id] = percentage
				}
			})

			const newRows = loadedData.topicTrainingMatrix.data.data.map(item => {
				let totalSubject = item.topics.filter(totalItem => totalItem.target_completion != null).length
				let greenTrainingStatus = item.topics.filter(
					subItem =>
						(subItem.training_status == 'green.date_completed' ||
							subItem.training_status == 'green.target_completion' ||
							subItem.training_status == 'white.date_completed' ||
							subItem.training_status == 'white.target_completion' ||
							subItem.training_status == 'amber.date_completed' ||
							subItem.training_status == 'amber.target_completion') &&
						subItem.target_completion != null,
				).length
				let calPercentage = Math.round((greenTrainingStatus / totalSubject) * 100)
				tableColumns.map(column => {
					if (column.id) {
						const subject = item.topics.find(item => item.id === column.id)
						if (subject) {
							subjectsInfo[column.id] = { ...subject }
						} else {
							subjectsInfo[column.id] = { ...column }
						}
					} else if (column.column === 'date_start') {
						subjectsInfo[column.column] = item.account.start_date
					} else {
						subjectsInfo[column.column] = item[column.column]
					}
				})
				return {
					...subjectsInfo,
					group: item.groups.length ? item.groups[0].name : accountName,
					learner_compliance: totalSubject != 0 ? calPercentage + '%' : '-%',
					id: item.id,
					email: item.email,
					username: item.username,
				}
			})

			newRows.unshift(courseCompletedCountRow, courseComplianceCountRow)
			return newRows
		} else return []
	}, [loadedData, tableColumns])

	const getCleanedUpFilters = useCallback(() => {
		const newFilter = {}

		Object.keys(filters).forEach(item => {
			if (
				typeof filters[item] === 'number' ||
				typeof filters[item] === 'boolean' ||
				filters[item] === null ||
				filters[item].length
			) {
				newFilter[item] = filters[item]
			}
		})

		return newFilter
	}, [filters])

	const exportFilters = useMemo(() => ({ ...getCleanedUpFilters(), page: null }), [getCleanedUpFilters])

	const [exportFetch, exportLoading] = useExport(EXPORT_TRAINING_MATRIX, exportFilters)

	const loadMore = useCallback(() => {
		const filters = getCleanedUpFilters()
		const newPage = loadedData.topicTrainingMatrix.data.current_page + 1
		fetchMore({
			variables: { filters: { ...filters, page: newPage } },
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev

				const array = prev.topicTrainingMatrix.data.data
				const mergedData = { ...fetchMoreResult }

				const newData = array.concat(mergedData.topicTrainingMatrix.data.data)

				mergedData.topicTrainingMatrix.data.data = [...newData]
				return mergedData
			},
		})
	}, [getCleanedUpFilters, getMatrixData, usersData, loadedData])

	const handleFilter = useCallback(() => {
		const sortDir = isASC ? 'asc' : 'desc'
		const filters = getCleanedUpFilters()

		getMatrixData({ variables: { filters: { ...filters, sort_direction: sortDir } } })
		if (isSubjectsFlag) {
			getSubjects({ variables: { filters } })
			setSubjectsFlag(false)
		} else {
			refetchSubjects({ filters: filters })
		}
	}, [refetch, isASC, refetchSubjects, getCleanedUpFilters])

	const handleUpdateQuery = useCallback(
		data => {
			const { value, id, first_name, last_name, email, username } = data

			const profileFilters = {
				id: parseInt(id),
				first_name: first_name,
				last_name: last_name,
				start_date: value || null,
				email: email || '',
				username: username || '',
			}

			Object.keys(profileFilters).map(item => {
				if (!profileFilters[item] && item !== 'start_date') {
					delete profileFilters[item]
				}
			})
			updateProfileMutation({
				variables: profileFilters,
			})
				.then(({ data: { updateProfile } }) => {
					if (Boolean(updateProfile) === true) {
						toastManager.add('Date is updated', { appearance: 'success', autoDismiss: true })
						refetch({ filters: { ...getCleanedUpFilters() } })
					}
				})
				.catch(() => {
					toastManager.add('Failed', { appearance: 'error' })
				})
		},
		[updateQuery, filters, refetch],
	)

	useEffect(() => {
		const sortDir = isASC ? 'asc' : 'desc'
		const filters = !isLearner ? { ...initialFilters, active: true } : { learners: [id], ...initialFilters }

		setFilters(filters)

		if (refetch) {
			if (isSubjectsFlag) {
				getSubjects({ variables: { filters } })
				setSubjectsFlag(false)
			}
			refetch({ filters: { ...filters, sort_direction: sortDir } })
		}
	}, [isLearner, id])

	// useEffect(() => {
	// 	const sortDir = isASC ? 'asc' : 'desc'
	// 	const filters = getCleanedUpFilters()
	//
	// 	getSubjects({ variables: { filters } })
	// 	getMatrixData({ variables: { filters: { ...filters, sort_direction: sortDir } } })
	// }, [])

	useEffect(() => {
		const sortDir = isASC ? 'asc' : 'desc'
		const filters = getCleanedUpFilters()

		if (refetch) {
			refetch({ filters: { ...filters, sort_direction: sortDir } })
		}
	}, [isASC])

	return (
		<div className="matrix-wrap">
			{loading ||
				(subjectLoading && (
					<div className="loader-wrap">
						<Loader active />
					</div>
				))}
			<div className="matrix-header">
				<h1>Training Matrix</h1>
				<Link className="button-link" to={Routes.DASHBOARD}>
					Back to Dashboard
				</Link>
				<Button
					type="button"
					size="medium"
					className="button-link"
					loading={exportLoading}
					disabled={exportLoading}
					content="Export to Excel"
					onClick={exportFetch}
				/>
			</div>
			<div className="matrix-filter-wrap">
				<Filter
					accountRole={accountRole}
					setFilters={setFilters}
					accountName={accountName}
					accountRoleType={accountRoleType}
					filters={filters}
					isLearner={isLearner}
				/>
				<button disabled={loading} type="button" className="button-link" onClick={handleFilter}>
					Run Report
				</button>
			</div>
			{loading && (
				<div className="loader-wrap">
					<Loader active />
				</div>
			)}
			{usersData.length > 0 && tableColumns.length > 0 && (
				<Grid
					columns={tableColumns}
					currentPage={loadedData ? loadedData.topicTrainingMatrix.data.current_page : 1}
					lastPage={loadedData ? loadedData.topicTrainingMatrix.data.last_page : 1}
					totalLearners={loadedData ? loadedData.topicTrainingMatrix.data.total : 1}
					filters={filters}
					rows={usersData}
					loadMore={loadMore}
					setFilters={setFilters}
					loading={loading}
					setIsAsc={setIsAsc}
					isASC={isASC}
					handleFilter={handleFilter}
					handleUpdateQuery={handleUpdateQuery}
					isLearner={isLearner}
				/>
			)}
			<IntroCtaMessage data={loadedData} loading={loading} />
		</div>
	)
}

Matrix.propTypes = {
	toastManager: PropTypes.object.isRequired,
}

export default withRouter(withToastManager(Matrix))
