import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { getObsLearningStats } from 'Utils/obs'
import Viewed from './Viewed'
import NotViewed from './NotViewed'
import StartLearning from './StartLearning'

export default function useLearningStatus({ obsLearningStats: learningStats, lessonId: obsModuleLessonId, lessons }) {
	const { userId } = useParams()
	const history = useHistory()

	const handleLessonNavigate = () => history.push(`${window.location.pathname}/lesson/${obsModuleLessonId}`)

	const statusProps = {
		onClick: () => !userId && handleLessonNavigate(),
	}

	const getLearningStatus = () => {
		const { size } = getObsLearningStats({ learningStats, obsModuleLessonId })
		if (size) {
			return (
				<Viewed {...statusProps} className={classNames(!userId && 'outcome-learning-status_viewed')}>
					{size === lessons.length + 1 ? 'Lesson viewed' : 'Continue learning'}
				</Viewed>
			)
		}

		return userId ? <NotViewed /> : <StartLearning {...statusProps} />
	}

	return getLearningStatus
}
