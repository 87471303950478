import React from 'react'
import PropsTypes from 'prop-types'
import classNames from 'classnames'

const Viewed = ({ className, children, ...restProps }) => (
	<button {...restProps} type="button" className={classNames('view-learning-btn', className)}>
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
			<path
				d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7
					64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112
					256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16
					16-16zm0 64H272c8.8  0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16
					16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z "
				style={{ fill: 'rgb(0, 98, 170)' }}
			/>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
			<path
				d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6
					9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4
					24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
				style={{ fill: 'rgb(130, 201, 30)' }}
			/>
		</svg>
		<div className="view-learning-btn_hint">{children}</div>
	</button>
)

Viewed.defaultProps = {
	onClick: () => {},
	className: '',
}

Viewed.propTypes = {
	onClick: PropsTypes.func,
	className: PropsTypes.string,
	children: PropsTypes.node.isRequired,
}

export default Viewed
