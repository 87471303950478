import React from 'react'
import Centered from 'Layouts/Centered'
import PropTypes from 'prop-types'
import ExceptionFallback from 'Common/ExceptionFallback'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		// Update state to display fallback UI
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// Log the error if needed
		console.error('ErrorBoundary caught an error:', error, errorInfo)
	}

	render() {
		const { hasError } = this.state
		const { children } = this.props

		if (hasError) {
			// Render fallback UI
			return <ExceptionFallback type="AppErrorBoundary" WrapperComponent={Centered} />
		}

		// Render children if no error
		return children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
}

ErrorBoundary.defaultProps = {
	children: null,
}

export default ErrorBoundary
