import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { Routes } from 'Constants'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { withContext } from 'Context'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { ProgressBar, Table } from 'Common'
import { setExportToPdf } from 'Actions/appActions'
import EditSubjectData from 'Modals/subjects/EditSubjectData'

import SectionItem from 'Pages/Subjects/SectionItem'
import UserCard from 'Pages/Subjects/UserCard'

import Certificate from 'Pages/Subjects/SingleSubject/Certificate'

import 'Pages/Subjects/SingleSubject/style.scss'
import useBreadcrumbs from './useBreadcrumbs'

// TODO: refactor this component since we are using it in 2 places
const ObsCourseContent = ({ setBreadcrumbs, userRole, userRoleType, data, subject }) => {
	const [editSubjectModal, setEditSubjectModal] = useState({ open: false })
	const [user, setUser] = useState(null)

	const { userSubjectId, userId } = useParams()

	const {
		name,
		description,
		created_at,
		img,
		target_completion,
		refresh_period,
		refresh_period_formatted,
		progress,
		last_viewed,
		sections,
		is_completed,
		feedback_link,
		date_completed,
		certificate,
		obs_course_id,
		obs_module_id,
	} = data.byId

	const getSubjectSectionLink = subjectSection => {
		const prefix = userId ? [Routes.MANAGER, userId, Routes.SUBJECTS.path.slice(1)] : [Routes.SUBJECTS.path]

		return [
			...prefix,
			userSubjectId,
			subjectSection.id,
			OBS_SUBJECT_PREFIX,
			obs_course_id,
			subjectSection.obs_module_id,
		].join('/')
	}

	const getSubjectSectionByModuleId = obsModuleId =>
		obsModuleId && sections.find(section => String(section.obs_module_id) === String(obsModuleId))

	useBreadcrumbs({
		userId,
		name,
		setBreadcrumbs,
		user,
	})

	const subjectSection = getSubjectSectionByModuleId(obs_module_id)

	if (obs_module_id && subjectSection) {
		return <Redirect to={getSubjectSectionLink(subjectSection)} />
	}

	const targetCompletion = moment(target_completion)
	const period = targetCompletion.diff(moment(created_at), 'days')
	const daysLeft = targetCompletion.diff(moment(), 'days')
	const dateCompleted = is_completed ? moment(date_completed).format('DD/MM/YYYY') : null
	const remainingInPercents = ((period - daysLeft) / period) * 100

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<div className="single-subject">
				<div className="title">
					<div className={classNames('info', { flex: userId })}>
						{userId && <UserCard setUser={setUser} />}
						<h1>{name || subject.name}</h1>
						{!userId && <p>{description || subject.description}</p>}
					</div>
					<div className="subject-progress">
						<ProgressBar
							title="Course progress"
							tooltip="Course progress based on assessments completed for all course sections."
							percents={progress}
						/>
						<ProgressBar
							title={
								is_completed ? (
									`Signed Off on ${dateCompleted}`
								) : (
									<>
										<span>
											{`Target end date: ${
												targetCompletion.isValid()
													? targetCompletion.format('DD/MM/YYYY')
													: 'not set'
											}`}
										</span>
										{userId && !isReviewer && (
											<button
												className="edit_end_date"
												type="button"
												onClick={() =>
													setEditSubjectModal({
														open: true,
														field: 'target_completion',
														initialValue: targetCompletion.isValid()
															? targetCompletion.format('DD-MM-YYYY')
															: '',
													})
												}
											>
												<i className="fas fa-pen" />
											</button>
										)}
									</>
								)
							}
							percents={target_completion ? (remainingInPercents < 100 ? remainingInPercents : 100) : 0}
							inDays
							color={is_completed ? 'primary' : 'secondary'}
						/>
						<div className="progress-bar">
							<div className="label-bar">Refresh period: {refresh_period_formatted}</div>
							{userId && !isReviewer && (
								<button
									className="edit_end_date"
									type="button"
									onClick={() =>
										setEditSubjectModal({
											open: true,
											field: 'refresh_period',
											initialValue: +refresh_period,
										})
									}
								>
									<i className="fas fa-pen" />
								</button>
							)}
						</div>
					</div>
				</div>

				{Boolean(!userId) && (
					<div className="actions">
						{last_viewed ? (
							<Link className="button-link" to={getSubjectSectionLink(last_viewed)}>
								{`Continue ${last_viewed.name}`}
							</Link>
						) : (
							<Link className="button-link" to={getSubjectSectionLink(sections[0])}>
								{`Start ${sections[0].name}`}
							</Link>
						)}
						<span>Or choose a section below</span>
					</div>
				)}

				{!_.isEmpty(sections) && (
					<Table className="sections-table">
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Section name</Table.HeaderCell>
								<Table.HeaderCell>
									<span>Progress</span>
								</Table.HeaderCell>
								<Table.HeaderCell>
									<span>Status</span>
								</Table.HeaderCell>
								<Table.HeaderCell />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(sections, section => (
								<SectionItem
									key={section.id}
									pivotId={Number.parseInt(userSubjectId)}
									{...section}
									getSubjectSectionLink={getSubjectSectionLink}
								/>
							))}
						</Table.Body>
					</Table>
				)}

				{is_completed && certificate && (
					<Certificate {...certificate} isManager={Boolean(userId)} subject={{ img, feedback_link }} />
				)}
			</div>

			{userId && !isReviewer && editSubjectModal.open && (
				<EditSubjectData {...editSubjectModal} onClose={() => setEditSubjectModal({ open: false })} />
			)}
		</>
	)
}

ObsCourseContent.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	data: PropTypes.object.isRequired,
	subject: PropTypes.object,
}

ObsCourseContent.defaultProps = {
	subject: {},
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
			app: { export_to_pdf },
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		exportToPdf: export_to_pdf,
		setExportPdf: data => setExportToPdf(data, dispatch),
	}),
	withBreadcrumbs(ObsCourseContent),
)
