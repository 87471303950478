import React from 'react'

const RebrandingHeader = () => (
	<header className="migrate-banner">
		<h2>Do you know a care provider who needs help with shift cover?</h2>
		<p>
			Our newest product, <b>Click Shifts</b>, connects care providers who need shift cover, with local carers
			just like you and it has recently launched in the Northwest. But as we expand, we&#39;d love for you to help
			us spread the word!
		</p>
		<p>
			If you know or work for a care provider who struggles to fill their short-term shifts and need local,
			vetted, reliable care workers to help out, send them our way and you could bag yourself up to £250 once
			they&#39;ve booked their first few shifts with us as a thank you (T&Cs apply). Simply{' '}
			<a href="https://flourish.co.uk/click-shifts/refer/">click here</a>, tell us a few details, and leave the
			rest to us.
		</p>
	</header>
)

export default RebrandingHeader
