import React, { memo } from 'react'
import PropTypes from 'prop-types'
import FormComponent from 'Helpers/form'

const ActionSlot = ({ className, children, confirmationInputProps }) => (
	<div className={className}>
		<FormComponent {...confirmationInputProps} />
		{children}
	</div>
)

ActionSlot.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	confirmationInputProps: PropTypes.object,
}

ActionSlot.defaultProps = {
	className: '',
	confirmationInputProps: {},
}

export default memo(ActionSlot)
