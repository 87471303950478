import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Divider from '../Divider'
import useLearningStatus from './useLearningStatus'

const LearningStatus = ({ dividerSlot, ...restProps }) => {
	const getLearningStatus = useLearningStatus(restProps)

	return (
		<div className="outcome-learning-status">
			{getLearningStatus()}
			{dividerSlot}
		</div>
	)
}

LearningStatus.defaultProps = {
	dividerSlot: <Divider />,
}

LearningStatus.propTypes = {
	dividerSlot: PropTypes.node,
}

export default memo(LearningStatus)
