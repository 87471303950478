import React from 'react'

const ObsModuleOutcomeListHeader = () => (
	<li className="outcome-item title obs-outcome-item">
		<div className="ref">
			<span className="custom-label">Ref</span>
		</div>
		<div className="name">
			<span className="custom-label">
				<span className="hidden-smm">Outcome </span>
				name
			</span>
		</div>
		<div className="learning-status custom-label">Learning</div>
		<div className="assessment-status custom-label">Lessons</div>
	</li>
)

export default ObsModuleOutcomeListHeader
