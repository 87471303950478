import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import { GET_SUBJECT_SECTION_BY_ID } from '../SubjectSectionInfo/queries'

const withUserSubjectSectionQuery = Component => {
	const DecoratedComponent = props => {
		const { match } = props

		const {
			params: { pivotId: userSubjectId, sectionId: userSubjectSectionId },
		} = match

		const { loading, error, data } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
			variables: {
				pivot_id: parseInt(userSubjectId),
				id: parseInt(userSubjectSectionId),
			},
			fetchPolicy: 'network-only',
		})

		const getContentProps = useCallback(({ data, ...restProps }) => {
			if (data.byId) {
				return {
					...data.byId,
					...restProps,
					title: data.byId.name,
				}
			}
			return {}
		}, [])

		return (
			<QueryAgnosticLayout
				{...props}
				data={data}
				getContentProps={getContentProps}
				loading={loading || !data}
				error={error}
				renderContent={Component}
			/>
		)
	}

	DecoratedComponent.propTypes = {
		match: PropTypes.object.isRequired,
	}

	return DecoratedComponent
}

export default withUserSubjectSectionQuery
