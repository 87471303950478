import React, { memo } from 'react'
import { ProgressBar } from 'Common'
import useObsModuleContent from '../useObsModuleContent'

const LearningProgress = props => {
	const { getLearningProgress } = useObsModuleContent(props)

	return (
		<ProgressBar
			title="Learning progress"
			tooltip="Learning progress based on viewed lessons for the current course."
			percents={getLearningProgress()}
		/>
	)
}

export default memo(LearningProgress)
