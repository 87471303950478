import { useQuery } from '@apollo/react-hooks'
import { GET_CURRENT_ACCOUNT } from '../store/queries/usersQueries'

export const useCourseBundle = accountSubscriptionId => {
	const state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
	const user = state ? state.user : null
	const { data, loading } = useQuery(GET_CURRENT_ACCOUNT, {
		variables: { id: parseInt(user.id) },
		fetchPolicy: 'network-only',
	})

	let subscriptions = []
	if (data && data.byId && data.byId.currentAccount && data.byId.currentAccount.subscriptions) {
		subscriptions = data.byId.currentAccount.subscriptions
	}

	let courseBundleOptions = []
	if (subscriptions && Array.isArray(subscriptions) && !accountSubscriptionId) {
		courseBundleOptions = subscriptions
			.filter(subscription => subscription && subscription.courseBundle && subscription.courseBundle.name)
			.map(subscription => {
				return {
					key: subscription.id,
					max_users_count: subscription.max_users_count || 0,
					current_users_count: subscription.current_users_count || 0,
					value: subscription.courseBundle.name,
					text: subscription.courseBundle.name,
				}
			})
	}

	let bundle = null
	const subscriptionId =
		courseBundleOptions.length > 0 && !accountSubscriptionId
			? courseBundleOptions[0].key
			: accountSubscriptionId || null
	if (subscriptions && Array.isArray(subscriptions)) {
		bundle = subscriptions.find(subscription => subscription.id == subscriptionId)
	}

	return {
		bundle,
		loading,
	}
}
