import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Certificate from 'Pages/Subjects/SingleSubject/Certificate'

const ObsModuleCertificate = ({ is_completed, certificate, ...restSubjectProps }) => {
	const { userId } = useParams()

	if (!is_completed || !certificate) {
		return null
	}

	return <Certificate {...certificate} isManager={Boolean(userId)} subject={restSubjectProps} />
}

ObsModuleCertificate.propTypes = {
	is_completed: PropTypes.bool.isRequired,
	certificate: PropTypes.object.isRequired,
}

export default ObsModuleCertificate
