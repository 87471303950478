import React from 'react'

const NotViewed = () => (
	<button type="button" className="view-learning-btn outcome-learning-status_not-viewed">
		<svg className="margin-props" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 384 512">
			<path
				d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0
					35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
			/>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
			<path
				d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4
					24.6-9.4 33.9 0l47 47 47-47 c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0
					33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9  0 l-47-47-47
					47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6  0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
				style={{ fill: 'rgb(138, 162, 211)' }}
			/>
		</svg>
		<div className="view-learning-btn_hint">Not viewed</div>
	</button>
)

export default NotViewed
