import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import { GET_SUBJECT_SECTION_BY_ID } from 'Pages/Subjects/SubjectSectionInfo/queries'
import { GET_SUBJECT_BY_ID } from 'Pages/Subjects/SingleSubject/queries'
import useObsModule from './useObsModule'
import useObsLessons from './useObsLessons'
import ObsModuleContent from './ObsModuleContent'

const ObsModule = () => {
	const { userId, userSubjectId, subjectSectionId, obsCourseId, obsModuleId } = useParams()

	const subject = useQuery(GET_SUBJECT_BY_ID, {
		variables: { pivot_id: Number.parseInt(userSubjectId), user_id: Number.parseInt(userId) },
		fetchPolicy: 'cache-first',
	})

	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: {
			id: Number.parseInt(subjectSectionId),
			pivot_id: Number.parseInt(userSubjectId),
			limit: 1,
			user_id: Number.parseInt(userId),
		},
		fetchPolicy: 'network-only',
	})

	const { lessons, isLoading: isLessonsLoading } = useObsLessons({ moduleId: obsModuleId })

	const { module, isLoading: isModule } = useObsModule({ courseId: obsCourseId, moduleId: obsModuleId })

	const getContentProps = useCallback(({ data, ...restProps }) => {
		if (data.byId) {
			return {
				...restProps,
				data,
				title: data.byId.name,
			}
		}
		return {}
	}, [])

	const getModuleLoading = () => {
		return loading || isModule || isLessonsLoading || subject.loading
	}

	return (
		<QueryAgnosticLayout
			loading={getModuleLoading()}
			error={subject.error || error}
			renderContent={ObsModuleContent}
			getContentProps={getContentProps}
			subject={subject.data}
			data={data}
			module={module}
			lessons={lessons}
		/>
	)
}

export default ObsModule
