import React from 'react'
import PropTypes from 'prop-types'
import ScormSubjectActions from './ScormSubjectActions'
import ScormSubjectLearnerStats from './ScormSubjectLearnerStats'

const ScormSubjectLearner = ({ subjectInfoSlot, subjectCertificateSlot, ...restSubjectProps }) => (
	<>
		{subjectInfoSlot}
		<ScormSubjectActions {...restSubjectProps} />
		<div className="title mt_25">
			<ScormSubjectLearnerStats {...restSubjectProps} />
			{subjectCertificateSlot}
		</div>
	</>
)

ScormSubjectLearner.propTypes = {
	subjectInfoSlot: PropTypes.node.isRequired,
	subjectCertificateSlot: PropTypes.node.isRequired,
}

export default ScormSubjectLearner
