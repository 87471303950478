import React from 'react'
import { Popup } from 'semantic-ui-react'
import PropsTypes from 'prop-types'

const StartLearning = props => (
	<Popup
		content="Start Learning"
		trigger={
			<button {...props} type="button" className="view-learning-btn outcome-learning-status_start-learning">
				<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
					<path
						d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7
					64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112
					256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16
					16-16zm0 64H272c8.8  0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16
					16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z "
						style={{ fill: 'rgb(0, 98, 170)' }}
					/>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
					<path
						className="fa-fade"
						d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3
				0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8
				12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5
				12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3  0s-12.5 32.8 0 45.3L210.7
				256 73.4  393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z "
						style={{ fill: 'rgb(255, 149, 0)' }}
					/>
				</svg>
				<div className="view-learning-btn_hint">Start Learning</div>
			</button>
		}
	/>
)

StartLearning.defaultProps = {
	onClick: () => {},
}

StartLearning.propTypes = {
	onClick: PropsTypes.func,
}

export default StartLearning
