import React from 'react'
import PropTypes from 'prop-types'
import ObsModuleOutcome, { ObsModuleOutcomeListHeader } from './ObsModuleOutcome'
import useObsModuleOutcomes from './useObsModuleOutcomes'

const ObsModuleOutcomes = ({ learningOutcomes, obsLearningStats, lessons }) => {
	const { getOutcomes } = useObsModuleOutcomes({ learningOutcomes, lessons })

	const renderModuleOutcomes = () => {
		const outcomes = getOutcomes()

		return outcomes.map(outcomeProps => (
			<ObsModuleOutcome key={outcomeProps.index} {...outcomeProps} obsLearningStats={obsLearningStats} />
		))
	}

	return (
		<div className="manage-outcomes">
			<div className="page-title">
				<div>
					<h1>Outcomes</h1>
				</div>
			</div>
			<ul className="list-outcomes">
				<ObsModuleOutcomeListHeader />
				{renderModuleOutcomes()}
			</ul>
		</div>
	)
}

ObsModuleOutcomes.propTypes = {
	learningOutcomes: PropTypes.string.isRequired,
	obsLearningStats: PropTypes.string,
	lessons: PropTypes.array.isRequired,
}

ObsModuleOutcomes.defaultProps = {
	obsLearningStats: null,
}

export default ObsModuleOutcomes
