import React, { useMemo, useCallback, useState } from 'react'
import classNames from 'classnames'
import { GroupSelect } from 'Common/GroupSelect'
import { LearnersSelect } from 'Common/LearnersSelect'
import { TopicSelect } from 'Common/TopicSelect'
import { SelectOneTag } from 'Common/TagsSelect/SelectOneTag'
import PropTypes from 'prop-types'
import FormComponent from 'Helpers/form'
import isNan from 'lodash/isNaN'
import { userGroupRoles } from 'Constants'
import './style.scss'
import _ from 'lodash'

const statusFilterOptions = [
	{
		key: 0,
		value: 'white',
		text: 'White',
	},
	{
		key: 1,
		value: 'green',
		text: 'Green',
	},
	{
		key: 2,
		value: 'amber',
		text: 'Amber',
	},
	{
		key: 3,
		value: 'red',
		text: 'Red',
	},
]

const tooltips = {
	groups: `Filter your report by selecting one or more Groups from the list and then click Run Report. 
		Start typing if you cannot see the Group in the list.`,
	learners: `Filter your report by selecting one or more Learners from the list and then click Run Report. 
		Start typing if you cannot see the Learner in the list.`,
	subjects: `Filter your report by selecting one or more Subjects from the list and then click Run Report. 
		Start typing if you cannot see the Subject in the list.`,
	status: (
		<span>
			Select one or more colours and Run the Report. Choose: <br />
			<b>Amber</b> to see Learners who need to complete the course and are close to the due date. <br />
			<b>Green</b> to see Learners who have completed the course and are within the due date if a refresher has
			been set.
			<br />
			<b>Red</b> to see Learners who have not completed a course and have passed the due date.
			<br /> <b>White</b> to see Learners who have not completed the course but the due date is still some time
			away.
			<br /> <p>&nbsp;</p>
			You can choose how close to the due date the colours will change by updating the Due in Days field.
		</span>
	),
	due_in_days: `Identify which courses are near their due date.  
		Courses which are due within this number of days will be displayed as amber.`,
}

const defaultLearnersFilter = {
	active: '',
	page: 1,
	role: '',
	status_evidence: '',
	subject: '',
	username: '',
}

const activityOptions = [
	{
		key: '0',
		text: 'All',
		value: 'all',
	},
	{
		key: '1',
		text: 'Active',
		value: true,
	},
	{
		key: '2',
		text: 'Inactive',
		value: false,
	},
]

const Filter = ({
	accountRole,
	accountName,
	accountRoleType,
	setFilters,
	//  subjects,
	filters,
	isLearner,
}) => {
	const [dayInDays, setDayInDays] = useState(30)
	const [selectedOptions, setSelectedOptions] = useState({
		groups: [],
		learners: [],
	})

	const learnersFilter = useMemo(() => {
		if (filters.groups && filters.groups.length) {
			return {
				...defaultLearnersFilter,
				groups: filters.groups,
			}
		}
	}, [filters])

	const groupsOptions = useMemo(() => {
		const groupsOptions = []

		if (accountRole === 1 && accountRoleType === 'account') {
			groupsOptions.unshift({
				key: 0,
				value: 0,
				text: accountName,
			})
		}

		return groupsOptions
	}, [accountRole, accountRoleType, accountName])
	const rolesOptions = _.map(userGroupRoles, ({ id, role }) => ({
		key: id,
		value: id,
		text: role,
	}))

	// const subjectsOptions = useMemo(() => {
	// 	return subjects.map(item => {
	// 		return {
	// 			...item,
	// 			text: item.name,
	// 			value: item.id,
	// 		}
	// 	})
	// }, [])

	const handleMultiSelectChange = useCallback(
		(e, { name, value, options }) => {
			const optionsCount = value.length - 1
			const option = options.find(option => option.value === value[optionsCount])

			setFilters(prevState => {
				return {
					...prevState,
					[name]: value,
				}
			})

			if (optionsCount >= 0) {
				setSelectedOptions({
					...selectedOptions,
					[name]: [...(selectedOptions[name] || []), option],
				})
			} else
				setSelectedOptions({
					...selectedOptions,
					[name]: [],
				})
		},
		[setFilters, setSelectedOptions, selectedOptions],
	)

	const handleSelectChange = useCallback(
		(e, { name, value }) => {
			if (value === 'all') {
				value = null
			}

			setFilters(prevState => {
				return {
					...prevState,
					[name]: value,
				}
			})
		},
		[setFilters, setSelectedOptions, selectedOptions],
	)

	const handleChangeType = useCallback(
		(e, data) => {
			if (typeof Number.parseInt(data.value) === 'number' && !isNan(Number.parseInt(data.value))) {
				setDayInDays(Number.parseInt(data.value))

				setFilters(prevState => {
					return {
						...prevState,
						[data.name]: Number.parseInt(data.value),
					}
				})
			} else if (!data.value) {
				setDayInDays(0)

				setFilters(prevState => {
					return {
						...prevState,
						[data.name]: 0,
					}
				})
			} else {
				setDayInDays(prevState => prevState)
			}
		},
		[setDayInDays],
	)

	return (
		<div className={classNames('filter-wrap', isLearner && 'is-learner')}>
			{!isLearner && (
				<>
					<GroupSelect
						label="Groups"
						name="groups"
						additionalOptions={groupsOptions}
						placeholder="Search groups"
						icon="angle down"
						selectOnBlur={false}
						fluid
						multiple
						tooltip={tooltips.groups}
						onChange={handleMultiSelectChange}
						selectedOptions={selectedOptions['groups']}
					/>
					<LearnersSelect
						type="dropdown"
						label="Learners"
						name="learners"
						tooltip={tooltips.learners}
						onChange={handleMultiSelectChange}
						learnersFilter={learnersFilter}
						placeholder="Search learners"
						selectOnBlur={false}
						multiple
						selectedOptions={selectedOptions['learners']}
						search
						selection
						clearable
						fluid
					/>
				</>
			)}

			{/* <FormComponent
				type="dropdown"
				label="Subjects"
				name="subjects"
				tooltip={tooltips.subjects}
				onChange={handleMultiSelectChange}
				placeholder="Search subjects"
				options={subjectsOptions}
				selectOnBlur={false}
				clearable
				multiple
				selection
				fluid
				search
			/> */}

			<FormComponent
				type="dropdown"
				label="Status"
				name="status"
				tooltip={tooltips.status}
				onChange={handleMultiSelectChange}
				placeholder="Select status"
				options={statusFilterOptions}
				selectOnBlur={false}
				clearable
				multiple
				selection
				fluid
			/>
			<FormComponent
				placeholder="Choose due in days"
				className="small day"
				customlabel="Due in days"
				tooltip={tooltips.due_in_days}
				name="due_in_days"
				type="default"
				onChange={handleChangeType}
				value={dayInDays}
			/>
			{!isLearner && (
				<>
					<FormComponent
						label="Role"
						name="role"
						type="dropdown"
						value={filters.role || ''}
						options={rolesOptions}
						onChange={handleSelectChange}
						placeholder="Role"
						icon="angle down"
						selection
						selectOnBlur={false}
						clearable
						fluid
					/>
					<FormComponent
						className="small"
						label="Active/Inactive"
						name="active"
						placeholder="Select type"
						type="dropdown"
						selection
						fluid
						value={typeof filters.active === 'boolean' ? filters.active : 'all'}
						sortBy="key"
						options={activityOptions}
						onChange={handleSelectChange}
					/>
				</>
			)}
			<TopicSelect
				value={filters.topic}
				onChange={handleSelectChange}
				selectOnBlur={false}
				multiple
				clearable
				customeName="topics"
			/>
			<SelectOneTag
				value={filters.tag}
				onChange={handleSelectChange}
				selectOnBlur={false}
				multiple
				clearable
				customeName="tags"
			/>
		</div>
	)
}

Filter.propTypes = {
	accountRole: PropTypes.number.isRequired,
	accountName: PropTypes.string.isRequired,
	accountRoleType: PropTypes.string.isRequired,
	setFilters: PropTypes.func.isRequired,
	// subjects: PropTypes.array.isRequired,
	filters: PropTypes.object.isRequired,
	isLearner: PropTypes.bool.isRequired,
}

export default Filter
