import React from 'react'
import { AccountSelector } from 'Modals/accounts'
import { AppNotifications } from '../AppNotifications'
import Impersonate from './Impersonate'

const SideEffects = () => {
	return (
		<>
			<Impersonate />
			<AppNotifications />
			<AccountSelector />
		</>
	)
}

export default SideEffects
