import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { detectIncognito } from 'detectincognitojs'
import { withContext } from 'Context'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import { Routes } from 'Constants'
import { createRegistration, buildRegistrationLaunchLink, syncRegistrationProgress } from 'Utils/scorm'
import ScormLearningContent from './ScormLearningContent'
import withUserSubjectSectionQuery from './withUserSubjectSectionQuery'

const ScormLearning = ({ match, user, title }) => {
	const {
		params: { pivotId: userSubjectId, sectionId: userSubjectSectionId },
	} = match

	const { push } = useHistory()

	const [isRegistrationLoading, setRegistrationLoading] = useState(true)
	const [launchLink, setLaunchLink] = useState()
	const [isIncognitoMode, setIncognitoMode] = useState()

	useEffect(() => {
		let isComponentMounted = true

		detectIncognito().then(({ isPrivate }) => {
			if (!isComponentMounted) {
				return
			}

			setIncognitoMode(isPrivate)

			if (isPrivate) {
				setRegistrationLoading(false)
				return
			}

			if (!userSubjectId || !userSubjectSectionId) {
				return
			}

			setRegistrationLoading(true)

			createRegistration({ userSubjectId, userSubjectSectionId })
				.finally(() => {
					const registrationLaunchLinkSettings = {
						userSubjectId,
						userSubjectSectionId,
						redirectOnExitUrl: `${window.location.origin}${Routes.SCORM_LEARNING_IFRAME_EXIT}`,
					}

					return buildRegistrationLaunchLink(registrationLaunchLinkSettings).then(({ data }) => {
						if (isComponentMounted) {
							setLaunchLink(data.launchLink)
						}
					})
				})
				.finally(() => {
					if (isComponentMounted) {
						setRegistrationLoading(false)
					}
				})
		})

		return () => {
			isComponentMounted = false
		}
	}, [userSubjectId, userSubjectSectionId])

	const subjectSectionLink = useMemo(() => {
		return `${Routes.SUBJECTS.path}/${userSubjectId}/${userSubjectSectionId}`
	}, [userSubjectId, userSubjectSectionId])

	const updateCourseProgress = useCallback(() => {
		syncRegistrationProgress({ userSubjectId, userSubjectSectionId, method: 'PUT' })
	}, [userSubjectId, userSubjectSectionId])

	const onExitLearning = useCallback(() => {
		updateCourseProgress()
		push(subjectSectionLink)
	}, [push, subjectSectionLink, updateCourseProgress])

	return (
		<QueryAgnosticLayout
			title={title}
			launchLink={launchLink}
			subjectSectionLink={subjectSectionLink}
			onExitLearning={onExitLearning}
			accessibility={user.accessibility}
			loading={isRegistrationLoading}
			renderContent={ScormLearningContent}
			isIncognitoMode={isIncognitoMode}
		/>
	)
}

ScormLearning.defaultProps = {
	title: '',
}

ScormLearning.propTypes = {
	match: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	title: PropTypes.string,
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => ({ user }),
	withUserSubjectSectionQuery(ScormLearning),
)
