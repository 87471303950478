import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AssessmentProgress from './AssessmentProgress'
import LearningProgress from './LearningProgress'

const ObsModuleStats = ({
	progress,
	firstAssessment,
	auto_sign_off_enabled,
	assessment,
	isManager,
	obsLearningStats,
	lessons,
}) => (
	<p className={classNames('section-progress', { manager: isManager })}>
		<AssessmentProgress progress={progress} />
		<LearningProgress obsLearningStats={obsLearningStats} lessons={lessons} />
		<br />
		{firstAssessment && (
			<>
				Date started: <span>{moment(firstAssessment.created_at).format('DD/MM/YYYY')}</span>
				<br />
			</>
		)}
		{auto_sign_off_enabled && assessment && (
			<>
				Number of retakes: <span>{assessment.retake}</span>
			</>
		)}
	</p>
)

ObsModuleStats.propTypes = {
	progress: PropTypes.number,
	firstAssessment: PropTypes.object,
	assessment: PropTypes.object,
	auto_sign_off_enabled: PropTypes.bool.isRequired,
	isManager: PropTypes.bool.isRequired,
	obsLearningStats: PropTypes.object,
	lessons: PropTypes.array,
}

ObsModuleStats.defaultProps = {
	firstAssessment: null,
	assessment: null,
	progress: 0,
	obsLearningStats: null,
	lessons: [],
}

export default ObsModuleStats
