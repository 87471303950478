import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, Button } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { GET_SUBJECT_SECTION_FOR_BREADCRUMBS } from 'Queries/subjectsQueries'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { CREATE_ASSESSMENT } from 'Mutations/assessmentMutations'
import useBreadcrumbs from '../useBreadcrumbs'

const AssessmentInstructionsContent = ({ data, setBreadcrumbs, toastManager }) => {
	const [startLoading, setStartLoading] = useState(false)

	const history = useHistory()
	const { userSubjectId, subjectSectionId, obsCourseId, obsModuleId } = useParams()

	const {
		id,
		retake,
		name,
		subject: { name: subjectName, auto_sign_off_enabled },
	} = data.byId

	const { loading } = useQuery(GET_SUBJECT_SECTION_FOR_BREADCRUMBS, {
		variables: { id: parseInt(id) },
		fetchPolicy: 'network-only',
	})

	const [createAssessment] = useMutation(CREATE_ASSESSMENT)

	useBreadcrumbs({
		subjectName,
		userSubjectId,
		subjectSectionId,
		setBreadcrumbs,
		obsCourseId,
		obsModuleId,
		name,
		lastBreadcrumbSegment: 'Assessment Instruction',
	})

	const onStart = () => {
		setStartLoading(true)

		createAssessment({
			variables: {
				subject_section_id: parseInt(id),
				retake: retake,
				pivot_id: parseInt(userSubjectId),
			},
		})
			.then(({ errors }) => {
				if (errors && errors.length) {
					toastManager.add(errors[0].debagMessage, { appearance: 'error' })

					return
				}

				history.push(
					[
						Routes.SUBJECTS.path,
						userSubjectId,
						subjectSectionId,
						OBS_SUBJECT_PREFIX,
						obsCourseId,
						obsModuleId,
						'assessment',
					].join('/'),
				)
			})
			.finally(() => {
				setStartLoading(false)
			})
	}

	return (
		<div className="assessment-instructions">
			<div className="page-title">
				<h1>Assessment Instructions</h1>
			</div>
			<div className="content">
				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<h3>{name}</h3>
						{auto_sign_off_enabled ? (
							<>
								<p>
									This is designed to help you evidence your knowledge and practice. It is not a test.
									Think about your job role and the people you interact with when answering the
									questions.
								</p>
								<p>
									When you have completed the assessment, check your answers and review the learning
									for any that need further action.
								</p>
								<p>
									Then retake the assessment. You won’t have to answer all the questions again, only
									those that require further action.
								</p>
							</>
						) : (
							<>
								<p>
									This is designed to help you evidence your knowledge and practice. It is not a test.
									Your answers should be about your job role and the people you support.
								</p>
								<p>
									To achieve 100% you must discuss the results with your supervisor, then add
									supporting evidence and examples of practice or observations.
								</p>
							</>
						)}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<p>
							Take your time and read each question carefully - there is no time limit Your selected
							answer(s) will be highlighted.
						</p>
						<p>
							When you are satisfied with your answer, select <Button content="Next" />
						</p>
						{!auto_sign_off_enabled ? (
							<>
								<p>
									If you are not sure of the correct answer select{' '}
									<Button className="reverse" content="Not sure" />
								</p>
								<p>
									If you select <Button className="reverse" content="It depends" /> add an explanation
									or example.
								</p>
							</>
						) : (
							<p>
								Click <Button className="reverse" content="Note" /> to add a comment which you can refer
								back to after the assessment.
							</p>
						)}
						<p>Assessments are saved automatically.</p>

						<div className="actions">
							<Button
								className="secondary"
								loading={startLoading}
								disabled={startLoading || loading}
								content="Start"
								onClick={onStart}
							/>
						</div>
					</Grid.Column>
				</Grid>
			</div>
		</div>
	)
}

AssessmentInstructionsContent.propTypes = {
	data: PropTypes.object,
	setBreadcrumbs: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}

AssessmentInstructionsContent.defaultProps = {
	data: {},
}

export default withBreadcrumbs(withToastManager(AssessmentInstructionsContent))
