import React, { memo } from 'react'
import PropsTypes from 'prop-types'

const Started = ({ completed, total }) => (
	<div className="outcome-learning-stats_started">
		<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
			<path
				d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6
					9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4
					24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
				style={{ fill: 'rgb(130, 201, 30)' }}
			/>
		</svg>
		<p>
			{completed}/{total}
		</p>
		<p>Viewed</p>
	</div>
)

Started.propTypes = {
	completed: PropsTypes.number.isRequired,
	total: PropsTypes.number.isRequired,
}

export default memo(Started)
