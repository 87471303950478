import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withToastManager } from 'react-toast-notifications'
import {
	ButtonContent,
	Button,
	Icon,
	Popup,
	Modal,
	Header,
	ModalContent,
	ModalActions,
	Divider,
} from 'semantic-ui-react'
import FormComponent from 'Helpers/form'
import { reportBrokenLink } from 'Utils/obs'

import './ReportBrokenLink.scss'

const ReportBrokenLink = ({ learningOutcomeName, page, subjectName, sectionName, toastManager, settings }) => {
	const [{ feedback }, setValues] = useState({ feedback: '' })
	const [isOpen, setOpen] = useState(false)
	const [isRequestProcessing, setRequestProcessing] = useState(false)

	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const handleFeedbackChange = (_, { value: feedback }) => {
		setValues(state => {
			const { maxLength } = settings.feedback

			return { ...state, feedback: feedback.length > maxLength ? state.feedback : feedback }
		})
	}

	const resetState = () => setValues({ feedback: '' })

	const handleSubmit = async () => {
		const toastSettings = {
			appearance: 'success',
			autoDismiss: true,
			message: 'Feedback submited successfully',
		}

		setRequestProcessing(true)

		try {
			await reportBrokenLink({ learningOutcomeName, page, subjectName, sectionName, feedback })

			setOpen(false)
			resetState()
		} catch {
			toastSettings.message = 'Something went wrong please try again later'
			toastSettings.appearance = 'error'
		} finally {
			toastManager.add(toastSettings.message, toastSettings)
			setRequestProcessing(false)
		}
	}

	return (
		<Modal
			closeIcon
			open={isOpen}
			trigger={
				<Popup
					trigger={
						<Button className="report-broken-link" animated="vertical" onClick={handleOpen}>
							<ButtonContent visible>
								<Icon name="bullhorn" color="black" />
							</ButtonContent>
							<ButtonContent hidden>Report broken link</ButtonContent>
						</Button>
					}
					content="Please click confirm to report a broken link on this page"
					position="top"
				/>
			}
			onClose={handleClose}
			onOpen={handleOpen}
		>
			<Header icon="bullhorn" content="Report broken link" />
			<ModalContent>
				<p>
					Broken links will normally be reviewed and corrected in 48 hours.
					<br />
					You can find out more about broken links on our help centre.
					<br />
					Please click confirm to report a broken link on this page
				</p>
			</ModalContent>
			<Divider />
			<div className="report-broken-link__feedback">
				<FormComponent.Textarea
					rows={6}
					label="Please write feedback here (optional)"
					name="feedback"
					placeholder="Feedback"
					value={feedback}
					onChange={handleFeedbackChange}
					fluid
				/>
			</div>
			<ModalActions>
				<Button onClick={handleSubmit} loading={isRequestProcessing}>
					Submit
				</Button>
			</ModalActions>
		</Modal>
	)
}

ReportBrokenLink.propTypes = {
	learningOutcomeName: PropTypes.string.isRequired,
	page: PropTypes.object.isRequired,
	subjectName: PropTypes.string.isRequired,
	sectionName: PropTypes.string.isRequired,
	settings: PropTypes.object,
	toastManager: PropTypes.object.isRequired,
}

ReportBrokenLink.defaultProps = {
	settings: {
		feedback: {
			maxLength: 5000,
		},
	},
}

export default withToastManager(ReportBrokenLink)
