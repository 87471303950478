import React from 'react'
import RootRoute from 'Routes/RootRoute'
import ErrorBoundary from 'Common/ErrorBoundary'
import 'semantic-ui-css/semantic.min.css'
import 'Styles/main.scss'

const App = () => (
	<ErrorBoundary>
		<RootRoute />
	</ErrorBoundary>
)

export default App
