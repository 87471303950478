import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useScormLearningExitIframe from './useScormLearningExitIframe'

const ScormLearningContent = ({
	isIncognitoMode,
	launchLink,
	subjectLink,
	showBackButton,
	onExitLearning,
	accessibility,
}) => {
	const iframeRef = useRef()

	const sendDataToIframe = () => {
		iframeRef.current.contentWindow.postMessage({ accessibility }, '*')
	}

	useScormLearningExitIframe({ onMessage: onExitLearning })

	if (isIncognitoMode) {
		return (
			<div className="section-learn">
				<div className="ui red message">
					This course will not play when using private or incognito browsing. Please sign in to Click using a
					standard browser session to complete this course.
				</div>
			</div>
		)
	}

	return (
		<div className="section-learn">
			{Boolean(showBackButton) && (
				<button type="button" className="page-title" onClick={onExitLearning}>
					<Link className="button-link primary" to={subjectLink}>
						Back to course
					</Link>
				</button>
			)}
			<div className="outcomes-wrap">
				<iframe
					src={launchLink}
					width="100%"
					ref={iframeRef}
					id="mainframe"
					title="learning"
					height="600px"
					onLoad={sendDataToIframe}
				/>
			</div>
		</div>
	)
}

ScormLearningContent.defaultProps = {
	launchLink: '',
	showBackButton: false,
	isIncognitoMode: null,
}

ScormLearningContent.propTypes = {
	accessibility: PropTypes.bool.isRequired,
	subjectLink: PropTypes.string.isRequired,
	onExitLearning: PropTypes.func.isRequired,
	launchLink: PropTypes.string,
	showBackButton: PropTypes.bool,
	isIncognitoMode: PropTypes.bool,
}

export default ScormLearningContent
