import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { App } from 'Constants/general'

const MailTo = ({ email }) => <a href={`mailto:${email}`}>{email}</a>

MailTo.propTypes = {
	email: PropTypes.string,
}

MailTo.defaultProps = {
	email: App.Support.Email,
}

export default memo(MailTo)
