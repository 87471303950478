import React from 'react'

const Divider = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="#F4F6FC"
		className="bi bi-chevron-compact-right svg-height"
		viewBox="0 0 16 16"
		id="IconChangeColor"
	>
		<path
			fillRule="evenodd"
			d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1
			1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
			id="mainIconPathAttribute"
			fill="#F4F6FC"
		/>
	</svg>
)

export default Divider
