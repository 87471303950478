import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Divider from './Divider'

const OutcomeContent = ({ content, dividerSlot }) => (
	<div className="outcome-list-content">
		<p>{content}</p>
		{dividerSlot}
	</div>
)

OutcomeContent.defaultProps = {
	dividerSlot: <Divider />,
}

OutcomeContent.propTypes = {
	content: PropTypes.string.isRequired,
	dividerSlot: PropTypes.node,
}

export default memo(OutcomeContent)
