import React, { memo } from 'react'
import { Grid, Icon, Loader } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { GET_LEARNER_DASHBOARD } from 'Queries/dashboardQueries'
import { useQuery } from '@apollo/react-hooks'
import queryString from 'query-string'
import tabs from './tabs'
import styles from './CoursesTabsNav.module.scss'

const CoursesTabsNav = () => {
	const { data: { learner = {} } = {}, loading } = useQuery(GET_LEARNER_DASHBOARD, {
		fetchPolicy: 'network-only',
	})

	return (
		<Grid className={styles.root}>
			<Grid.Row>
				{tabs.map(({ key, label, icon, activityStatus }) => (
					<Grid.Column key={key} mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
						<NavLink
							to={{
								pathname: '/courses',
								search: queryString.stringify({ activity_status: activityStatus }),
							}}
							exact
							className={classNames(styles.item, styles[key])}
						>
							<Icon className={classNames(styles.icon, icon)} />
							<span>{label} - </span>
							&nbsp;
							{loading ? (
								<Loader active inline inverted size="small" />
							) : (
								<span>{learner[key] || 0}</span>
							)}
						</NavLink>
					</Grid.Column>
				))}
			</Grid.Row>
		</Grid>
	)
}

export default memo(CoursesTabsNav)
