import React from 'react'
import { getObsLearningStats } from 'Utils/obs'
import NotStarted from './NotStarted'
import Started from './Started'

export default function useLearningStats({
	obsLearningStats: learningStats,
	lessonId: obsModuleLessonId,
	lessons = [],
}) {
	const getLearningStats = () => {
		const { size } = getObsLearningStats({ learningStats, obsModuleLessonId })

		if (size) {
			const total = lessons.length + 1

			return <Started completed={Math.min(size, total)} total={total} />
		}

		return <NotStarted />
	}

	return getLearningStats
}
