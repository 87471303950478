import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import SignOff from 'Modals/session/SignOff'

const GroupSignoff = ({ selectedUserIds, users, date, isOpen, onOpenChange }) => {
	const { sessionId } = useParams()

	const handleCloseModal = () => onOpenChange(false)

	const getUserMasterSessionPivotIds = () => {
		return users.reduce((acc, { id, sessions }) => {
			if (!selectedUserIds.includes(id)) {
				return acc
			}

			const session = sessions.find(session => Number(session.id) === Number(sessionId))

			if (session) {
				return [...acc, Number(session.pivot_id)]
			}

			return acc
		}, [])
	}

	const getSessionDate = () => {
		const sessionDate = moment(date, 'YYYY-MM-DD')

		return sessionDate.isValid() ? date : null
	}

	if (!isOpen) {
		return null
	}

	return (
		<SignOff
			open={isOpen}
			onClose={handleCloseModal}
			groupPivotIds={getUserMasterSessionPivotIds()}
			sessionDate={getSessionDate()}
		/>
	)
}

GroupSignoff.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onOpenChange: PropTypes.func.isRequired,
	users: PropTypes.arrayOf(PropTypes.object),
	selectedUserIds: PropTypes.arrayOf(PropTypes.number),
	date: PropTypes.string,
}

GroupSignoff.defaultProps = {
	users: [],
	selectedUserIds: [],
	date: '-',
}

export default GroupSignoff
