import React, { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { GET_ALL_USERS_LIST } from 'Queries/usersQueries'
import FormComponent from 'Helpers/form'
import PropTypes from 'prop-types'

const LearnersSelect = ({ learnersFilter, selectedOptions, ...props }) => {
	const [getLearners, { data: learners }] = useLazyQuery(GET_ALL_USERS_LIST, {
		fetchPolicy: 'network-only',
	})

	const debounceSearch = useCallback(
		_.debounce(
			query =>
				getLearners({ variables: { filters: { ...learnersFilter, page: null, username: query }, limit: 30 } }),
			300,
		),
		[],
	)

	const handleSearchChange = useCallback(
		(e, { searchQuery }) => {
			debounceSearch(searchQuery)
		},
		[learnersFilter],
	)

	const leadersOptions = useMemo(() => {
		return _.map(learners ? learners.allForManager.full_data : [], ({ id, first_name, last_name }) => ({
			key: id,
			value: id,
			text: `${first_name} ${last_name}`,
		})).concat(selectedOptions)
	}, [learners, selectedOptions])

	useEffect(() => {
		getLearners({
			variables: { filters: { ...learnersFilter }, limit: 30 },
		})
	}, [learnersFilter.groups])

	return <FormComponent options={leadersOptions} icon="angle down" onSearchChange={handleSearchChange} {...props} />
}

LearnersSelect.propTypes = {
	learnersFilter: PropTypes.object,
	selectedOptions: PropTypes.array,
}

LearnersSelect.defaultProps = {
	learnersFilter: {},
	selectedOptions: [],
}

export { LearnersSelect }
