import React, { memo } from 'react'
import MailTo from 'Common/MailTo'

const AppVersionChange = () => {
	const RefreshTrigger = (
		<button className="refresh-button" type="button" onClick={() => window.location.reload(true)}>
			refresh
		</button>
	)

	return (
		<>
			We have made some changes to this site. Please {RefreshTrigger} your browser to get the latest updates. If
			you are still having problems, please email &nbsp;
			<MailTo /> and let us know what you were trying to do. We will take a look and do our best to fix it for
			you.
		</>
	)
}

export default memo(AppVersionChange)
