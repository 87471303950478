import React from 'react'
import PropTypes from 'prop-types'
import UserCard from '../../UserCard'
import ScormSubjectLearnerStats from './ScormSubjectLearnerStats'

const ScormSubjectManager = ({ subjectInfoSlot, subjectCertificateSlot, setUser, ...restSubjectProps }) => (
	<>
		<div className="title">
			<UserCard setUser={setUser} />
		</div>
		{subjectInfoSlot}
		<div className="title">
			<ScormSubjectLearnerStats {...restSubjectProps} />
			{subjectCertificateSlot}
		</div>
	</>
)

ScormSubjectManager.propTypes = {
	subjectInfoSlot: PropTypes.node.isRequired,
	subjectCertificateSlot: PropTypes.node.isRequired,
	setUser: PropTypes.func.isRequired,
}

export default ScormSubjectManager
