import React from 'react'
import PropsTypes from 'prop-types'
import classNames from 'classnames'

const IntroCtaMessage = ({ data, loading }) => {
	if (data || loading) {
		return null
	}

	return (
		<div className={classNames('mt_25', 'training-matrix-intro-cta')}>
			<p>To load the report select the filters and tags and then click “Run Report”.</p>
			<p>To load the full matrix just click “Run Report” but note that it will take longer to load. </p>
			<p>
				If you click “Export to Excel” before running the report it will download the full report to Excel.
				Depending on the number of learners you have this may take many minutes.
			</p>
		</div>
	)
}

IntroCtaMessage.propTypes = {
	data: PropsTypes.object,
	loading: PropsTypes.bool.isRequired,
}

IntroCtaMessage.defaultProps = {
	data: null,
}

export default IntroCtaMessage
