import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { GET_SUBJECT_SECTION_BY_ID } from 'Pages/Subjects/SubjectSectionInfo/queries'

const AssessmentButton = ({ disabled, onClick }) => {
	const { userSubjectId, subjectSectionId, obsCourseId, obsModuleId } = useParams()

	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: {
			id: Number.parseInt(subjectSectionId),
			pivot_id: Number.parseInt(userSubjectId),
			limit: 1,
		},
		fetchPolicy: 'cache-and-network',
	})

	const { retake, is_completed, is_start } = useMemo(() => {
		if (data && data.byId) {
			return data.byId
		}

		return {}
	}, [data])

	const renderAssessmentButtonContent = () => {
		if (retake) {
			return 'Retake Assessment'
		}

		if (is_completed) {
			return 'Assessment Completed'
		}

		if (is_start) {
			return 'Continue Assessment'
		}

		return 'Start Assessment'
	}

	const getAssessmentUrl = () => {
		return [
			Routes.SUBJECTS.path,
			userSubjectId,
			subjectSectionId,
			OBS_SUBJECT_PREFIX,
			obsCourseId,
			obsModuleId,
			'assessment-instructions',
		].join('/')
	}

	const isDisabled = disabled || is_completed || loading || error

	const handleClick = event => (isDisabled ? event.preventDefault() : onClick(event))

	const render = () => {
		const Component = (
			<>
				{renderAssessmentButtonContent()}
				{is_completed && <Icon name="check" />}
			</>
		)

		if (!isDisabled) {
			return (
				<Link
					className="button-link secondary"
					to={getAssessmentUrl()}
					disabled={isDisabled}
					onClick={handleClick}
				>
					{Component}
				</Link>
			)
		}

		return <span className="button-link secondary">{Component}</span>
	}

	return render()
}

AssessmentButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
}

AssessmentButton.defaultProps = {
	onClick: () => {},
	disabled: false,
}

export default AssessmentButton
