import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_SUBJECT_SECTION_BY_ID } from 'Pages/Subjects/SubjectSectionInfo/queries'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import useObsLesson from './useObsLesson'
import ObsLearningContent from './ObsLessonContent'

const ObsLesson = () => {
	const { obsModuleLessonId, subjectSectionId, userSubjectId, userId } = useParams()

	const { lesson, isLoading } = useObsLesson({ lessonId: obsModuleLessonId })
	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: {
			id: Number.parseInt(subjectSectionId),
			pivot_id: Number.parseInt(userSubjectId),
			limit: 1,
			user_id: Number.parseInt(userId),
		},
		fetchPolicy: 'cache-first',
	})

	return (
		<QueryAgnosticLayout
			error={error}
			loading={isLoading || loading}
			renderContent={ObsLearningContent}
			lesson={lesson}
			title="Obs Module Lesson"
			subjectSectionData={data && data.byId}
		/>
	)
}

export default ObsLesson
