import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageContent, PageMetaTags } from 'Common'
import { Loader, Tab } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { withContext } from 'Context'
import { ACCOUNT_SESSION_LIST } from 'Queries/settingQueries'
import { useParams } from 'react-router-dom'
import { useCourseBundle } from 'Hooks/useCourseBundle'
import SubjectList from './SubjectList'
import SessionList from './SessionList'
import Filter from './Filter'
import './style.scss'

const PAGE_NAME = 'Add Courses'

const AddUserCourses = ({ setBreadcrumbs, customFilter }) => {
	const { accountSubscriptionId } = useParams()
	const [sessionVisible] = useState(false)
	const { bundle, loading } = useCourseBundle(accountSubscriptionId)

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Learners',
				path: '/learners',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					<SubjectList />
				</Tab.Pane>
			),
		},
		!sessionVisible
			? {
					menuItem: 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionList sessionData={sessionData} sessionLoading={sessionLoading} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]
	const { data: sessionData, loading: sessionLoading } = useQuery(ACCOUNT_SESSION_LIST, {
		variables: {
			filters: {
				title: customFilter.username,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
				tag_id: parseInt(customFilter.group),
				statusNot: [2, 3],
			},
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (sessionData) {
			if (sessionData.accountSessions.data.length > 0) sessionVisible(true)
		}
	}, [sessionData])
	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="users-page">
				<PageContent.Content>
					<div className="users-list">
						<div className="course-list-bundle">
							<div className="page-title">
								<h1>Courses & Sessions</h1>
							</div>
						</div>
						{loading ? (
							<Loader active />
						) : (
							<div className="tabs-view">
								<div role="button" tabIndex="0" className="bundle-info">
									<div className="license-subscription">
										<h3>Bundle Name:</h3>
										<p>{(bundle && bundle.courseBundle && bundle.courseBundle.name) || '-'} </p>
									</div>
									<div className="license-subscription">
										<h3>License used:</h3>
										<p>{(bundle && bundle.current_users_count) || 0}</p>
									</div>
									<div className="license-subscription">
										<h3>Total License:</h3>
										<p>{(bundle && bundle.max_users_count) || 0}</p>
									</div>
								</div>
							</div>
						)}
						<div className="tabs-view">
							<Tab panes={panes} />
						</div>
					</div>
				</PageContent.Content>

				<PageContent.RightBar>
					<Filter />
				</PageContent.RightBar>
			</PageContent>
		</>
	)
}

AddUserCourses.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	customFilter: PropTypes.object.isRequired,
}

export default withContext(
	([
		{
			users: { customFilter, checkAvailableForGroupSubjects },
		},
	]) => ({
		// state
		customFilter,
		checkAvailableForGroupSubjects,
	}),
	withBreadcrumbs(AddUserCourses),
)
