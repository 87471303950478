import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import { GET_SUBJECT_SECTION_BY_ID } from 'Pages/Subjects/SubjectSectionInfo/queries'
import AssessmentInstructionsContent from './AssessmentInstructionsContent'

const ObsAssessmentInstructions = () => {
	const { userSubjectId, subjectSectionId } = useParams()

	const { data, loading, error } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: {
			id: Number.parseInt(subjectSectionId),
			pivot_id: Number.parseInt(userSubjectId),
			limit: 1,
		},
		fetchPolicy: 'network-only',
	})

	const getContentProps = ({ data, ...restProps }) => {
		if (data.byId) {
			return {
				...restProps,
				data,
				title: data.byId.name,
			}
		}

		return {}
	}

	return (
		<QueryAgnosticLayout
			loading={loading}
			error={error}
			renderContent={AssessmentInstructionsContent}
			getContentProps={getContentProps}
			data={data}
		/>
	)
}

export default ObsAssessmentInstructions
