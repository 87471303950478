/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MultiGrid, AutoSizer } from 'react-virtualized'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { Icon, Popup } from 'semantic-ui-react'
import FormComponent from 'Helpers/form'

import './style.scss'

const STYLE_TOP_RIGHT_GRID = {
	borderBottom: '1px solid #CACED6',
	borderRight: '1px solid #CACED6',
	fontWeight: 'bold',
	color: 'white',
	fontSize: '12px',
	backgroundColor: '#6191dc',
	cursor: 'pointer',
}

const STYLE_CELL = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderBottom: '1px solid #CACED6',
	borderRight: '1px solid #CACED6',
}

const STYLE_HEADER_CELL = {
	backgroundColor: '#ffffff',
	display: 'flex',
	alignItems: 'center',
	fontWeight: 'bold',
	color: 'black',
	fontSize: '12px',
	borderBottom: '1px solid #CACED6',
	borderRight: '1px solid #CACED6',
}

const rgx = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/

const Grid = ({
	columns,
	rows,
	loadMore,
	lastPage,
	currentPage,
	setFilters,
	filters,
	loading,
	setIsAsc,
	isASC,
	totalLearners,
	handleUpdateQuery,
	isLearner,
}) => {
	const [scrollCount, setScrollCount] = useState(0)
	const [activeCell, setActiveCell] = useState('first_name')
	const [currentData, setCurrentData] = useState({})
	const [dateError, setDateError] = useState(false)

	const gridRef = useRef()
	const pickerRef = useRef()

	const handleCellClick = useCallback(
		e => {
			setIsAsc(!isASC)
			const sortField = e.currentTarget.dataset.column
			setFilters({
				...filters,
				sort_param: sortField,
			})
			setActiveCell(sortField)
		},
		[setFilters, setIsAsc, isASC, setActiveCell],
	)

	const handleChangeDate = useCallback(
		(e, data) => {
			let filteredValue = data.value.replace(/[^\d]/g, '')
			let i = 0
			filteredValue = filteredValue.replace(/(\d{2})/g, match => {
				if (i < 2) {
					i++
					return match + '.'
				}
				return match
			})

			if (filteredValue.lastIndexOf('.') === filteredValue.length - 1 || filteredValue.length > 10) {
				filteredValue = filteredValue.substring(0, filteredValue.length - 1)
			}

			setCurrentData(prevState => {
				return { ...prevState, [data.id]: filteredValue }
			})

			if (filteredValue.length === 10 && filteredValue.match(rgx)) {
				handleUpdateQuery(data)
				setDateError({ [data.id]: false })
			} else {
				setDateError({ [data.id]: true })
			}
		},
		[handleUpdateQuery, setCurrentData, setDateError],
	)

	const handleClearDate = useCallback(
		(e, data) => {
			setCurrentData(prevState => {
				return { ...prevState, [data.id]: null }
			})
			handleUpdateQuery(data)
		},
		[setCurrentData, handleUpdateQuery],
	)
	const handlePickerPosition = useCallback(
		e => {
			e.preventDefault()
			const picker = pickerRef.current.children[pickerRef.current.children.length - 1]

			let top
			let bottom

			if (picker) {
				top = picker.style.top
				bottom = picker.style.bottom

				picker.style.top = `calc(${top} - 260px)`
				picker.style.bottom = `calc(${bottom} - 70px)`
			}
		},
		[pickerRef],
	)

	const RowRenderer = useCallback(
		({ columnIndex, key, rowIndex, style }) => {
			if (columns.length) {
				if (rowIndex === 0 || rowIndex === 1) {
					const cell = rows[rowIndex][columns[columnIndex].id]
					if (typeof cell === 'number') {
						const comliancePercentage = cell ? `${cell}%` : '-'
						return (
							<div className="cell" key={key} style={{ ...STYLE_HEADER_CELL, ...style }}>
								{rowIndex === 0 ? cell : comliancePercentage}
							</div>
						)
					} else if (!cell && columnIndex === 2)
						if (rowIndex === 1) {
							return (
								<div key={key} className="cell count-cell-right" style={{ ...STYLE_HEADER_CELL }}>
									<Popup
										content={rows[rowIndex].tooltip}
										trigger={
											<span className="tooltip">
												{rows[rowIndex].name} <i className="fas fa-info-circle" />
											</span>
										}
									/>
								</div>
							)
						} else {
							return (
								<div className="cell count-cell" key={key} style={{ ...STYLE_HEADER_CELL }}>
									<div>
										Total Learners <span className="total">{totalLearners}</span>
									</div>
									<Popup
										content={rows[rowIndex].tooltip}
										trigger={
											<span className="tooltip">
												{rows[rowIndex].name} <i className="fas fa-info-circle" />
											</span>
										}
									/>
								</div>
							)
						}
				} else if (rowIndex === 2) {
					return (
						<div
							onClick={columnIndex < 3 ? handleCellClick : () => {}}
							data-column={columns[columnIndex].column}
							className={classNames('cell', { active: columns[columnIndex].column === activeCell })}
							key={key}
							style={{ ...STYLE_TOP_RIGHT_GRID, ...style }}
						>
							{columns[columnIndex].name}
							{columns[columnIndex].column === activeCell && (
								<Icon name={`angle ${isASC ? 'down' : 'up'}`} />
							)}
						</div>
					)
				} else if (rows[rowIndex - 1]) {
					const cell = rows[rowIndex - 1][columns[columnIndex].id]
					let cellTextDate
					let colour

					if (cell && cell.training_status) {
						const { training_status } = cell

						const [cellColour, dateField] = training_status.split('.')
						colour = cell[dateField] ? cellColour : null
						cellTextDate = cell[dateField] ? moment(cell[dateField]).format('DD.MM.YYYY') : null
					}
					if (columns[columnIndex].column === 'date_start') {
						const dateValue = rows[rowIndex - 1] ? rows[rowIndex - 1][columns[columnIndex].column] : ''
						const formatedDate = moment(dateValue).format('DD.MM.YYYY')
						const { id, first_name, last_name, email, username } = rows[rowIndex - 1]
						return (
							<div className={classNames('cell', colour)} key={key} style={{ ...STYLE_CELL, ...style }}>
								<FormComponent
									placeholder="Select date"
									className={classNames('field-wrap text', {
										error: dateError[id],
									})}
									name="date_start"
									type="date"
									mountNode={pickerRef.current}
									dateFormat="DD.MM.YYYY"
									onChange={handleChangeDate}
									columnindex={columnIndex}
									rowindex={rowIndex - 3}
									id={id}
									disabled={isLearner}
									onFocus={handlePickerPosition}
									initialDate={null}
									first_name={first_name}
									last_name={last_name}
									email={email}
									username={username}
									value={currentData[id] || (dateValue ? formatedDate : '')}
									onClear={handleClearDate}
									popupPosition="bottom right"
								/>
							</div>
						)
					} else {
						return (
							<div className={classNames('cell', colour)} key={key} style={{ ...STYLE_CELL, ...style }}>
								{cellTextDate ? cellTextDate : rows[rowIndex - 1][columns[columnIndex].column]}
							</div>
						)
					}
				}
			}
		},
		[columns, rows, filters, totalLearners, currentData, isLearner],
	)

	const handleScroll = useCallback(
		e => {
			const { scrollHeight, scrollTop } = e
			const leftHeight = scrollHeight - scrollTop
			const leftHeightPercentage = (leftHeight * 100) / scrollHeight

			if (scrollCount < 1) {
				if (Math.ceil(leftHeightPercentage) < 100 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (scrollCount < 2) {
				if (Math.ceil(leftHeightPercentage) < 60 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (scrollCount < 5) {
				if (Math.ceil(leftHeightPercentage) < 50 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (scrollCount < 10) {
				if (Math.ceil(leftHeightPercentage) < 25 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (scrollCount < 20) {
				if (Math.ceil(leftHeightPercentage) < 20 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (scrollCount < 30) {
				if (Math.ceil(leftHeightPercentage) < 15 && currentPage < lastPage && !loading) {
					setScrollCount(scrollCount + 1)
					loadMore()
				}
			} else if (Math.ceil(leftHeightPercentage) < 10 && currentPage < lastPage && !loading) {
				loadMore()
			}
		},
		[loadMore, currentPage, lastPage, scrollCount, setScrollCount],
	)

	const handleRowHeight = useCallback(({ index }) => {
		if (index === 0 || index === 1) return 35
		if (index === 2) return 70
		return 50
	}, [])

	const handleColumnWidth = useCallback(({ index }) => {
		if (index < 3) return 140
		return 170
	}, [])

	useEffect(() => {
		const grid = gridRef.current
		if (grid) {
			grid.state.scrollTop = 0
		}
	}, [filters])

	useEffect(() => {
		const grid = gridRef.current
		if (grid) {
			grid.forceUpdateGrids()
			grid.recomputeGridSize()
		}
	}, [filters, rows, currentData])

	return (
		<div className="grid-wrap">
			{rows.length > 2 ? (
				<AutoSizer className="wrap">
					{({ height, width }) => (
						<MultiGrid
							ref={gridRef}
							cellRenderer={RowRenderer}
							columnWidth={handleColumnWidth}
							columnCount={columns.length}
							fixedColumnCount={5}
							fixedRowCount={3}
							rowHeight={handleRowHeight}
							rowCount={rows.length + 1}
							width={columns.length < 10 ? 170 * (columns.length - 3) + 140 * 3 + 10 : width}
							height={rows.length < 10 ? 50 * (rows.length + 1) : height}
							onScroll={handleScroll}
						/>
					)}
				</AutoSizer>
			) : (
				<h3>No results match your search criteria</h3>
			)}
			<div className="pickerWrap" ref={pickerRef} />
		</div>
	)
}

Grid.propTypes = {
	columns: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	loadMore: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	setFilters: PropTypes.func.isRequired,
	handleUpdateQuery: PropTypes.func.isRequired,
	setIsAsc: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	lastPage: PropTypes.number.isRequired,
	loading: PropTypes.bool.isRequired,
	isASC: PropTypes.bool.isRequired,
	isLearner: PropTypes.bool.isRequired,
	totalLearners: PropTypes.number.isRequired,
}

export default Grid
