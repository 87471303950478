import React from 'react'
import PropTypes from 'prop-types'
import { App } from 'Constants/general'

const Support = ({ actionSlot }) => (
	<div className="bottom-info">
		{Boolean(actionSlot) && <div className="link-wrap">{actionSlot}</div>}
		<div className="link-wrap">
			<span>Having trouble signing in? Check our </span>
			<a className="link" href={App.Support.General} tabIndex="-1">
				support page
			</a>
		</div>
	</div>
)

Support.defaultProps = {
	actionSlot: null,
}

Support.propTypes = {
	actionSlot: PropTypes.node,
}

export default Support
