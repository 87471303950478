import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import { GET_SUBJECT_BY_ID } from 'Pages/Subjects/SingleSubject/queries'
import ObsCourseContent from './ObsCourseContent'
import useObsCourse from './useObsCourse'

const ObsCourse = () => {
	const { userSubjectId, obsCourseId, userId } = useParams()

	const { data, error, loading } = useQuery(GET_SUBJECT_BY_ID, {
		variables: { pivot_id: Number.parseInt(userSubjectId), user_id: Number.parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	const { subject, isLoading } = useObsCourse({ courseId: obsCourseId })

	const getContentProps = useCallback(({ data, ...restProps }) => {
		if (data.byId) {
			return {
				...restProps,
				data,
				title: data.byId.name,
			}
		}
		return {}
	}, [])

	return (
		<QueryAgnosticLayout
			loading={isLoading || loading}
			error={error}
			renderContent={ObsCourseContent}
			getContentProps={getContentProps}
			data={data}
			subject={subject}
		/>
	)
}

export default ObsCourse
