import React, { memo } from 'react'
import PropsTypes from 'prop-types'

const ObsModuleDescription = ({ content }) => <p dangerouslySetInnerHTML={{ __html: content }} />

ObsModuleDescription.propTypes = {
	content: PropsTypes.string.isRequired,
}

export default memo(ObsModuleDescription)
