import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import { PageMetaTags } from 'Common'

const QueryAgnosticLayout = ({ loading, error, renderContent, getContentProps, ...restProps }) => {
	if (loading) {
		return <Loader active />
	}

	if (error) {
		return <span>Something went wrong :(</span>
	}

	const props = getContentProps(restProps)

	return (
		<>
			<PageMetaTags {...props} />
			{renderContent(props)}
		</>
	)
}

QueryAgnosticLayout.defaultProps = {
	getContentProps: p => p,
	error: false,
	loading: false,
}

QueryAgnosticLayout.propTypes = {
	renderContent: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	error: PropTypes.bool,
	getContentProps: PropTypes.func,
}

export default QueryAgnosticLayout
