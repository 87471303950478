import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { withToastManager } from 'react-toast-notifications'
import { Grid, Button } from 'semantic-ui-react'
import { ProgressBar } from 'Common'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { useForm } from 'Hooks'
import { answerAssessmentQuestion } from 'Utils/obs'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import QuestionType from 'Pages/Assessment/Questions/QuestionType'
import useBreadcrumbs from './useBreadcrumbs'

const ObsAssessmentContent = ({ data, assessment, setBreadcrumbs, toastManager }) => {
	const [loading, setLoading] = useState(false)
	const isLoadingRef = useRef(false)

	const redirectToModuleRef = useRef()

	const history = useHistory()

	const [completeQuestionCount, setCompleteQuestionsCount] = useState(0)

	const { userSubjectId, subjectSectionId, obsCourseId, obsModuleId } = useParams()

	const {
		name,
		subject: { name: subjectName },
		user_subject_section_id: userSubjectSectionId,
	} = data.byId

	useBreadcrumbs({
		subjectName,
		userSubjectId,
		subjectSectionId,
		setBreadcrumbs,
		obsCourseId,
		obsModuleId,
		name,
		lastBreadcrumbSegment: 'Assessment',
	})

	redirectToModuleRef.current = () => {
		const link = [
			Routes.SUBJECTS.path,
			userSubjectId,
			subjectSectionId,
			OBS_SUBJECT_PREFIX,
			obsCourseId,
			obsModuleId,
		]

		history.push(link.join('/'))
	}

	useEffect(() => {
		const { questions } = assessment
		if (Array.isArray(questions) && questions.length > 0) {
			const startingIndex = questions
				.sort(question => (question.actioned ? -1 : 1))
				.findIndex(({ actioned }) => !actioned)

			if (startingIndex > -1) {
				setCompleteQuestionsCount(startingIndex)
				return
			}

			redirectToModuleRef.current()
		}
	}, [assessment])

	const { title, introduction, questions = [] } = assessment

	const question = questions[completeQuestionCount]

	const validate = values => {
		const errors = {}

		if (values.answer && values.answer.length > 3000) {
			errors.answer = true
		}

		return errors
	}

	const {
		values: { answer, bool, multichoice, single, depends },
		handleSubmit,
		handleChange,
		handleChangeRadioGroup,
		handleChangeCheckboxGroup,
		clearValues,
		errors,
	} = useForm(() => onSubmit(), validate, {
		answer: '',
		bool: null,
		multichoice: [],
		single: '',
		depends: '',
	})

	const onSubmit = async () => {
		if (isLoadingRef.current) {
			return
		}

		isLoadingRef.current = true
		setLoading(true)

		try {
			await answerAssessmentQuestion({
				userSubjectSectionId,
				questionId: question.id,
				answerIds: [multichoice, single].flat(),
			})

			const completeCount = completeQuestionCount + 1

			if (completeCount < questions.length) {
				clearValues()
				setCompleteQuestionsCount(completeCount)
				return
			}

			redirectToModuleRef.current()

			toastManager.add('You have completing the assessment', {
				appearance: 'success',
				autoDismiss: true,
			})
		} catch (error) {
			console.error(error)
			toastManager.add('Something went wrong, please try again later or refresh the page', {
				appearance: 'error',
				autoDismiss: true,
			})
		} finally {
			setLoading(false)
			isLoadingRef.current = false
		}
	}

	if (!question) {
		return null
	}

	return (
		<div className="questions-wrap">
			<div className="page-title">
				<h1>{title}</h1>
				<p dangerouslySetInnerHTML={{ __html: introduction }} />
			</div>
			<ProgressBar
				title="Completion"
				percents={Math.ceil((completeQuestionCount / questions.length) * 100).toString()}
			/>
			<div className="content">
				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<span className="title">
							Question {completeQuestionCount + 1} of {questions.length}
						</span>
						<p className="question">{question.title}</p>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<form onSubmit={handleSubmit}>
							<QuestionType
								answer={answer}
								bool={bool}
								multichoice={multichoice}
								single={single}
								type={4} // <- this is a type of question checkbox
								options={question.answers}
								handleChange={handleChange}
								handleChangeRadioGroup={handleChangeRadioGroup}
								handleChangeCheckboxGroup={handleChangeCheckboxGroup}
								errors={errors}
							/>
							<div className="actions">
								<Button
									size="large"
									type="submit"
									loading={loading}
									disabled={Boolean(!answer && !bool && !multichoice.length && !single && !depends)}
									content="Next"
								/>
							</div>
						</form>
					</Grid.Column>
				</Grid>
			</div>
		</div>
	)
}

ObsAssessmentContent.propTypes = {
	data: PropTypes.object,
	assessment: PropTypes.object,
	setBreadcrumbs: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}

ObsAssessmentContent.defaultProps = {
	data: {},
	assessment: {},
}

export default withBreadcrumbs(withToastManager(ObsAssessmentContent))
