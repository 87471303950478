import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import QueryAgnosticLayout from 'Common/QueryAgnosticLayout'
import ScormSubjectContent from './ScormSubjectContent'

const Scorm = props => {
	const getContentProps = useCallback(props => ({ ...props, title: props.name }), [])

	return <QueryAgnosticLayout {...props} getContentProps={getContentProps} renderContent={ScormSubjectContent} />
}

Scorm.propTypes = {
	name: PropTypes.string.isRequired,
}

export default Scorm
