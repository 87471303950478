import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withToastManager } from 'react-toast-notifications'
import { GET_USER } from '../../../store/queries/usersQueries'
import { createImpersonateClient } from '../../../store'
import { withContext } from '../../../context'
import { impersonateSuccessAction } from '../../../store/actions/authActions'

const Impersonate = ({ impersonateSuccess, impersonate, toastManager }) => {
	const impersonateCredentials = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const userId = Number(urlParams.get('id'))
		const accountId = Number(urlParams.get('accountId'))
		const token = urlParams.get('impersonate')
		if (token && userId && accountId) {
			return { token, userId, accountId }
		}
		return null
	}, [])

	useEffect(() => {
		if (impersonate.active) {
			toastManager.add('You already use impersonate. Quit the previous one to use the new one', {
				appearance: 'error',
			})
			return
		}

		if (impersonateCredentials) {
			const { token, userId, accountId } = impersonateCredentials
			createImpersonateClient(token, accountId)
				.query({
					query: GET_USER,
					variables: { id: userId },
					fetchPolicy: 'network-only',
				})
				.then(({ data: { byId } }) =>
					impersonateSuccess({
						...byId,
						token,
					}),
				)
		}
	}, [impersonateCredentials])

	return null
}

Impersonate.propTypes = {
	impersonateSuccess: PropTypes.func.isRequired,
	impersonate: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
}

export default withToastManager(
	withContext(
		([{ user }, dispatch]) => ({
			impersonate: user.impersonate,
			// actions
			impersonateSuccess: data => impersonateSuccessAction(data, dispatch),
		}),
		Impersonate,
	),
)
