import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'
import FormComponent from 'Helpers/form'
import { useForm } from 'Hooks'
import { withContext } from 'Context'
import { Routes } from 'Constants'
import { PageMetaTags, Support } from 'Common'
import { signInSuccessAction } from 'Actions/authActions'
import useTwoFactorAuth from 'Hooks/useTwoFactorAuth'
import clickLogo from 'Assets/images/click_logo_transparent.png'

const TwoFactorForm = ({ signInSuccess, toastManager }) => {
	const location = useLocation()
	const [loading, setLoading] = useState(false)

	const { sentTwoFactorCode } = useTwoFactorAuth()

	useEffect(() => {
		if (!location.state) {
			window.location.href = Routes.LOGIN
		}
	}, [location.state])

	const {
		values: { code },
		handleSubmit,
		handleChange,
	} = useForm(() => {
		setLoading(true)

		sentTwoFactorCode({ userId: location.state.userId, code })
			.then(({ data: user }) => {
				signInSuccess(user)
				location.state = null
			})
			.catch(error => {
				toastManager.add(error.message, { appearance: 'error' })
			})
			.finally(() => {
				setLoading(false)
			})
	})

	return (
		<Fragment>
			<PageMetaTags title="2FA" />
			<img className="logo" src={clickLogo} alt="Click logo" />
			<span className="title">Please paste your code</span>

			<form onSubmit={handleSubmit}>
				<FormComponent
					autoCorrect="off"
					autoCapitalize="none"
					autoComplete="off"
					name="code"
					value={code || ''}
					onChange={handleChange}
					placeholder="2FA code"
					size="large"
					maxLength={4}
				/>
				<Button size="big" loading={loading} disabled={loading} fluid content="Verify" />
			</form>
			<Support
				actionSlot={
					<Link className="link" to={Routes.LOGIN} tabIndex="-1">
						Back to login
					</Link>
				}
			/>
		</Fragment>
	)
}

TwoFactorForm.propTypes = {
	toastManager: PropTypes.object.isRequired,
	signInSuccess: PropTypes.func.isRequired,
}

export default withToastManager(
	withContext(
		([, dispatch]) => ({
			// actions
			signInSuccess: data => signInSuccessAction(data, dispatch),
		}),
		TwoFactorForm,
	),
)
