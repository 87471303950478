import React from 'react'
import PropsTypes from 'prop-types'
import { Pagination } from 'semantic-ui-react'
import useObsLessonMaterials from './useObsLessonMaterials'

const ObsLessonMaterials = ({ content, activePage, setActivePage }) => {
	const { onPageChange, loading, error, paginatorContainerRef, pageContentRef } = useObsLessonMaterials({
		content,
		activePage,
		setActivePage,
	})

	if (!content.length || loading || error) {
		return null
	}

	return (
		<>
			<div ref={pageContentRef} className="page-content">
				<div dangerouslySetInnerHTML={{ __html: content[activePage].content }} />
			</div>
			<div ref={paginatorContainerRef} className="mt_25 obs-pagination-container">
				<Pagination
					boundaryRange={content.length}
					totalPages={content.length}
					activePage={activePage + 1}
					onPageChange={onPageChange}
				/>
			</div>
		</>
	)
}

ObsLessonMaterials.propTypes = {
	content: PropsTypes.array,
	activePage: PropsTypes.number.isRequired,
	setActivePage: PropsTypes.func.isRequired,
}

ObsLessonMaterials.defaultProps = {
	content: [],
}

export default ObsLessonMaterials
