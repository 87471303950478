import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import ScormSubjectInfo from './ScormSubjectInfo'
import ScormSubjectLearner from './ScormSubjectLearner'
import ScormSubjectManager from './ScormSubjectManager'
import useBreadcrumbs from './useBreadcrumbs'

const ScormSubjectContent = props => {
	const [user, setUser] = useState({})

	const { isLearnerView } = props

	useBreadcrumbs({ user, ...props })

	const ContentComponent = isLearnerView ? ScormSubjectLearner : ScormSubjectManager

	return (
		<div className="single-subject scorm-subject">
			<ContentComponent
				{...props}
				user={user}
				setUser={setUser}
				subjectInfoSlot={<ScormSubjectInfo {...props} />}
			/>
		</div>
	)
}

ScormSubjectContent.propTypes = {
	isLearnerView: PropTypes.bool.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(ScormSubjectContent)
