import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from 'semantic-ui-react'
import classNames from 'classnames'

const SubjectDetails = ({ name, description, img }) => (
	<Grid className="mt_25" reversed="mobile">
		<Grid.Column mobile={16} tablet={9} computer={9}>
			<div className={classNames(['page-title', 'flex'])}>
				<h1>{name}</h1>
				<p>{description}</p>
			</div>
			<div className="mb_18">
				<Divider />
			</div>
		</Grid.Column>
		<Grid.Column className="text-right" only="tablet computer" tablet={7} computer={7}>
			<img src={img} alt="" />
		</Grid.Column>
	</Grid>
)

SubjectDetails.defaultProps = {
	description: '',
}

SubjectDetails.propTypes = {
	name: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	description: PropTypes.string,
}

export default memo(SubjectDetails)
