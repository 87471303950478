import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
// import { AccessibilityDialog } from './widgets/AccessibilityDialog'
import AssessmentButton from '../../Shared/AssessmentButton'
import useObsModuleContent from './useObsModuleContent'

const ObsModuleLearnerActions = props => {
	const { defaultForceLearning } = props
	const { userId } = useParams()
	const { isLearningCompleted } = useObsModuleContent(props)

	if (userId) {
		return null
	}

	const isCompletedLearning = defaultForceLearning || isLearningCompleted()

	if (isCompletedLearning) {
		return (
			<div className="actions">
				<AssessmentButton />
				{/* {isCompletedLearning && (
					<AccessibilityDialog {...props} trigger={<Icon name="info circle" size="large" />} />
				)} */}
			</div>
		)
	}

	return (
		<div className="actions">
			<Popup
				trigger={
					<span>
						<AssessmentButton disabled />
					</span>
				}
			>
				<Popup.Content>
					In order to proceed to the assessment, you should complete all lessons in this course
				</Popup.Content>
			</Popup>
		</div>
	)
}

ObsModuleLearnerActions.propTypes = {
	defaultForceLearning: PropTypes.bool,
}

ObsModuleLearnerActions.defaultProps = {
	defaultForceLearning: false,
}

export default ObsModuleLearnerActions
