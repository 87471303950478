import React, { useState } from 'react'
import { withContext } from 'Context/index'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { profileUpdateAction } from 'Actions/profileActions'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import './style.scss'

const _AccessibilityNavButton = ({ user, updateProfileSuccess }) => {
	const [updateProfileMutation] = useMutation(UPDATE_PROFILE_MUTATION)
	const [accessibilitySaving, setAccessibilitySaving] = useState(false)

	const handleClick = (accessibility = false) => {
		setAccessibilitySaving(true)

		updateProfileMutation({
			variables: {
				id: parseInt(user.id),
				first_name: user.first_name,
				last_name: user.last_name,
				email: user.email,
				username: user.username,
				accessibility,
			},
		})
			.then(({ data: { updateProfile } }) => {
				if (Boolean(updateProfile) === true) {
					updateProfileSuccess({ ...user, accessibility })

					setAccessibilitySaving(false)
				}
			})
			.finally(() => window.location.reload())
	}

	const toggleAccessibility = () => {
		if (!accessibilitySaving) {
			handleClick(!user.accessibility)
		}
	}

	return (
		<div
			role="button"
			tabIndex="0"
			className="accessibilityButton"
			onClick={toggleAccessibility}
			onKeyDown={toggleAccessibility}
		>
			<button className="rootButton" type="button" disabled={accessibilitySaving}>
				<i className="fas fa-universal-access accessibility-icon" />
			</button>
			<span className="label">Accessibility</span>
		</div>
	)
}

_AccessibilityNavButton.propTypes = {
	user: PropTypes.object.isRequired,
	updateProfileSuccess: PropTypes.func.isRequired,
}

const AccessibilityNavButton = withRouter(
	withContext(
		([
			{
				user: { user },
			},
			dispatch,
		]) => ({
			user,
			updateProfileSuccess: data => profileUpdateAction(data, dispatch),
		}),
		_AccessibilityNavButton,
	),
)

export { AccessibilityNavButton }
