import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const AbstractComponent = ({ onInit, children }) => {
	const propsRef = useRef({ onInit })

	useEffect(() => {
		propsRef.current = {
			onInit,
		}
	}, [onInit])

	useEffect(() => {
		propsRef.current.onInit()
	}, [])

	return <>{children}</>
}

AbstractComponent.defaultProps = {
	onInit: () => {},
	children: null,
}

AbstractComponent.propTypes = {
	onInit: PropTypes.func,
	children: PropTypes.node,
}

export default AbstractComponent
