import React, { Fragment, memo } from 'react'
import { Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import MailTo from 'Common/MailTo'

const ExceptionMessage = {
	Generic: (
		<>
			Oops! We weren&apos;t expecting that to happen. Please try refreshing the page or email&nbsp;
			<MailTo /> &nbsp; and let us know what you were trying to do. We will take a look and do our best to fix it
			for you.
		</>
	),
	AppErrorBoundary: (
		<>
			<Header as="h2">Oops! We weren&apos;t expecting that to happen.</Header>
			<br />
			<Header size="small">
				Click here to continue: <a href="/">{window.location.origin}</a>
				<br />
				<br />
				The crash log will be reviewed by our developers.
				<br />
				<br />
				<Header
					size="tiny"
					style={{
						fontSize: '0.8em',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					However, if you need urgent support please email &nbsp; <MailTo />
					&nbsp; and our team will get back to you as soon as possible. Usually within 1 working day
				</Header>
			</Header>
		</>
	),
}

const ExceptionFallback = ({ WrapperComponent, type }) => <WrapperComponent>{ExceptionMessage[type]}</WrapperComponent>

ExceptionFallback.propTypes = {
	WrapperComponent: PropTypes.elementType,
	type: PropTypes.string,
}

ExceptionFallback.defaultProps = {
	WrapperComponent: Fragment,
	type: 'Generic',
}

export default memo(ExceptionFallback)
