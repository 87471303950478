import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Confirm } from 'semantic-ui-react'
import ActionSlot from './ActionSlot'

import styles from './IrreversibleConfirmation.module.scss'

const IrreversibleConfirmation = ({
	className,
	opened,
	size,
	content,
	confirmationHelperContent,
	confirmationInputProps,
	renderCancelButton,
	renderConfirmButton,
}) => {
	const [confirmInputValue, setConfirmInputValue] = useState('')

	useEffect(() => {
		if (!opened) {
			setConfirmInputValue('')
		}
	}, [opened])

	const renderContent = () => {
		return (
			<div className={styles.confirmationContent}>
				<div>{content}</div>
				<div className={styles.confirmationHelperText}>{confirmationHelperContent}</div>
			</div>
		)
	}

	return (
		<Confirm
			className={className}
			open={opened}
			size={size}
			content={renderContent()}
			cancelButton={
				<ActionSlot
					className={styles.confirmationActionContainer}
					confirmationInputProps={{
						...confirmationInputProps,
						value: confirmInputValue,
						onChange: (_, { value }) =>
							setConfirmInputValue(confirmationInputProps.getConfirmationInputValue(value)),
					}}
				>
					{renderCancelButton()}
					{renderConfirmButton(confirmInputValue)}
				</ActionSlot>
			}
			confirmButton={null}
		/>
	)
}

IrreversibleConfirmation.propTypes = {
	content: PropTypes.any.isRequired,
	className: PropTypes.string,
	opened: PropTypes.bool,
	size: PropTypes.string,
	confirmationHelperContent: PropTypes.any,
	confirmationInputProps: PropTypes.object,
	renderCancelButton: PropTypes.func,
	renderConfirmButton: PropTypes.func,
}

IrreversibleConfirmation.defaultProps = {
	className: '',
	opened: false,
	size: 'mini',
	confirmationHelperContent: null,
	renderCancelButton: () => null,
	renderConfirmButton: () => null,
	confirmationInputProps: {
		getConfirmationInputValue: v => v,
	},
}

export default memo(IrreversibleConfirmation)
