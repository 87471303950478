import React from 'react'
import AbstractComponent from 'Helpers/AbstractComponent'
import useScormLearningExitIframe from './useScormLearningExitIframe'

const ScormLearningExitIframe = () => {
	const { notifyParentFrame } = useScormLearningExitIframe()

	return <AbstractComponent onInit={notifyParentFrame} />
}

export default ScormLearningExitIframe
