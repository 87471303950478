import React, { memo } from 'react'
import useLearningStats from './useLearningStats'

const LearningStats = props => {
	const getLearningStats = useLearningStats(props)

	return <div className="outcome-learning-stats">{getLearningStats()}</div>
}

export default memo(LearningStats)
