import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Routes } from 'Constants'
import { isCompleted, isNotStarted } from 'Utils/scorm'

const ScormSubjectActions = ({ userSubjectId, userSubjectSectionId, ...restSubjectProps }) => {
	const renderButtonContent = () => {
		if (isCompleted(restSubjectProps)) {
			return 'Replay course'
		}

		if (isNotStarted(restSubjectProps)) {
			return 'Launch course'
		}

		return 'Continue learning'
	}

	return (
		<div className="actions">
			<Link
				className="button-link"
				to={`${Routes.SUBJECTS.path}/${userSubjectId}/${userSubjectSectionId}/e-learn`}
			>
				{renderButtonContent()}
			</Link>
		</div>
	)
}

ScormSubjectActions.propTypes = {
	userSubjectId: PropTypes.number.isRequired,
	userSubjectSectionId: PropTypes.number.isRequired,
}

export default memo(ScormSubjectActions)
