import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from 'Common'

const AssessmentProgress = ({ progress }) => (
	<ProgressBar
		title="Assessment progress"
		tooltip="Course sections progress based on answers completed for the current course section."
		percents={progress}
	/>
)

AssessmentProgress.propTypes = {
	progress: PropTypes.number.isRequired,
}

export default memo(AssessmentProgress)
